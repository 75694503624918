import React, { Fragment, useState,useEffect } from 'react';
import toast from "react-hot-toast";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TemplateService from "../../../services/Template/Template";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import { useSelector, useDispatch } from 'react-redux';
const ListMessage = (props) => {
  const currentUserData = useSelector(state => state.profile.profile);
  const [cardCount, setCardCount] = useState(1);
  const [itemCount, setItemCount] = useState({});
  const [initialValue, setInitialValue] = useState({
    idtemplate_data:'',
    template_name: "",
    template_message: "",
    template_title_header: "",
    template_footertext: "",
    template_buttontext_forselection: "",
    template_listoption: [
      {
        title: "",
        listoptionid: 1,
        rows: [
          {
            id: 1,
            listvaluename: "",
            description: "",
          },
        ],
      },
    ],
  });
  const searchSchema = Yup.object().shape({
    template_name: Yup.string().required("Cannot be empty"),
    template_message: Yup.string().required("Cannot be empty"),
    template_title_header: Yup.string().required("Cannot be empty"),
    template_footertext: Yup.string().required("Cannot be empty"),
    template_buttontext_forselection: Yup.string().required("Cannot be empty"),

    template_listoption: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("Cannot be empty"),
        rows: Yup.array().of(
          Yup.object().shape({
            listvaluename: Yup.string().required("Cannot be empty"),
            description: Yup.string().required("Cannot be empty"),
          })
        ),
      })
    ),
  });
  
useEffect(() => {
    if (props.isedit) {
        if (props.editTemplateData) {
            console.log(props.editTemplateData, "editTemplateData!!!!!!!!")
            setInitialValue(()=>({
              idtemplate_data:props.editTemplateData.idtemplate_data,
              template_name: props.editTemplateData.template_name,
              template_message:props.editTemplateData.template_message,
              template_title_header: props.editTemplateData.template_title_header,
              template_footertext: props.editTemplateData.template_footertext,
              template_buttontext_forselection: props.editTemplateData.template_buttontext_forselection,
              template_listoption: JSON.parse(props.editTemplateData.template_listoption),
            }))
           
        }
    }
}, [props.isedit])
console.log(initialValue,"initialvalues in list text!!!!")
  const addCard = () => {
    setCardCount(prevCount => prevCount + 1);
    // Initialize the item count for the new card to 1
    setItemCount(prevState => ({ ...prevState, [cardCount + 1]: 1 }));
  };

  const deleteCard = (cardIndex) => {
    if (cardCount > 1) {
      setCardCount(prevCount => prevCount - 1);
      // Remove the item count for the deleted card
      setItemCount(prevState => {
        const newState = { ...prevState };
        delete newState[cardIndex];
        return newState;
      });
    }
  };

  const addItem = (cardIndex) => {
    setItemCount(prevState => ({ ...prevState, [cardIndex]: (prevState[cardIndex] || 0) + 1 }));
  };

  const deleteItem = (cardIndex) => {
    if (itemCount[cardIndex] > 1) {
      setItemCount(prevState => ({ ...prevState, [cardIndex]: prevState[cardIndex] - 1 }));
    }
  };

  const renderCards = () => {
    const cards = [];
    for (let i = 1; i <= cardCount; i++) {
      const items = [];
      for (let j = 1; j <= (itemCount[i] || 1); j++) {
        items.push(
          <div className="row list-item-area1" key={j}>
            <div className="col-6">
              <div className="form-group">
                <label>Enter List Value Name</label>
                <input type="text" className="form-control itemval-1" name={`section[${i}][value][${j}][title]`} placeholder="Example: Banana" value="" required maxLength="50" />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <div className='row'>
                  <div className='col-md-9'>
                    <label>Enter List Value Description</label>
                  </div>
                  <div className='col-md-2' style={{ marginRight: "0px" }}>{j > 1 && <div className="col-12 text-right">
                    <button type="button" className="btn btn-sm btn-danger" onClick={() => deleteItem(i)}><i className="fa fa-times"></i></button>
                  </div>}</div>
                </div>
                <input type="text" className="form-control" name={`section[${i}][value][${j}][description]`} placeholder="Example: Banana is a healthy food" value="" maxLength="50" />
              </div>
            </div>

          </div>
        );
      }
      cards.push(
        <div className="card card-primary card-item" key={i} style={{ width: 'auto', borderRadius: '10px', padding: '8px', marginLeft: '-8px', marginBottom: '10px', marginTop: '2px' }}>
          <div className="card-header">
            <h4>List {i}</h4>
            {i > 1 && <button type="button" className="btn btn-sm btn-danger float-right" onClick={() => deleteCard(i)}><i className="fa fa-trash"></i></button>}
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label>List Section Title</label>
                  <input type="text" className="form-control" name={`section[${i}][title]`} placeholder="Example: Select a fruit" value="" required maxLength="50" />
                </div>
              </div>
            </div>
            {items}
            <div className="col-12 text-right">
              <button type="button" className="btn btn-sm btn-primary add-more-option-item option-item-btn1" onClick={() => addItem(i)}><i className="fas fa-plus"></i> Add More Item</button>
            </div>
          </div>
        </div>
      );
    }
    return cards;
  };
  const deleteForm = (values, index) => {
    const updatedListSection = [...values.template_listoption];
    updatedListSection.splice(index, 1);

    setInitialValue((prevState) => ({
      ...prevState,
      template_listoption: updatedListSection,
    }));
  };
  const deleteSectionMoreRowItem = (values, index, setFieldValue, rowindex) => {
    console.log(values, "deleteSectionMoreRowItem");
    const updatedSections = [...values.template_listoption];
    const sectionToUpdate = updatedSections[index];

    // Remove the row at the specified index
    sectionToUpdate.rows.splice(rowindex, 1);

    // Update the sections array in the state
    setFieldValue("template_listoption", updatedSections);
  };
  const [count, setCount] = useState(1);
  const increaseSectionMoreRowItem = (
    values,
    sectionIndex,
    setFieldValue,
    length
  ) => {
    const updatedSections = [...values.template_listoption];
    const sectionToUpdate = updatedSections[sectionIndex];

    // Increment row count within the section
    const newRowId = sectionToUpdate.rows.length + 1;

    // Create a new row
    const newRow = {
      id: newRowId,
      listvaluename: "",
      description: "",
    };

    // Add the new row to the section
    sectionToUpdate.rows.push(newRow);
    setFieldValue("template_listoption", updatedSections);
  };
  const increaseCount = (values, setFieldValue) => {
    setCount(count + 1);

    const newFamilyMember = {
      title: "",
      listoptionid: count + 1,
      rows: [
        {
          id: 1,
          listvaluename: "",
          description: "",
        },
      ],
    };
    setFieldValue("template_listoption", [...values.template_listoption, newFamilyMember]);
  };
  return (
    <div className="tab-pane" id="mode_5" role="tabpanel" aria-labelledby="profile-tab4">

      <Fragment>
        <Formik
          validationSchema={searchSchema}
          initialValues={initialValue}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
            console.log(values,"values in list item template")
            const data = {
              user_id: currentUserData?.currentUserData?.result?.user_id,
              idtemplate_data: values.idtemplate_data,
              template_name: values.template_name,
              template_message: values.template_message,
              template_footertext: values.template_footertext,
              template_title_header:values.template_title_header,
              template_buttontext_forselection:values.template_buttontext_forselection,
              template_listoption:values.template_listoption,
          }

          const saveTemplate = new TemplateService();
          if(props.isedit){
            saveTemplate
            .updateListMessageTemplateData(data)
            .then((response) => {
                // console.log(response,"response in add participant in group!!!!")
                if (response.isSuccess === true) {
                    toast.success(response?.message);
                } else {
                    toast.error(response?.message);
                }
                resetForm();
                setSubmitting(false)
                props.setEdit(false);
                props.getTemplates();
            })
            .catch((error) => {
                console.error("Error fetching user profile:", error);
            });
          }else{
            saveTemplate
            .saveListMessageTemplateData(data)
            .then((response) => {
                // console.log(response,"response in add participant in group!!!!")
                if (response.isSuccess === true) {
                    toast.success(response?.message);
                } else {
                    toast.error(response?.message);
                }
                resetForm();
                setSubmitting(false)
            })
            .catch((error) => {
                console.error("Error fetching user profile:", error);
            });
          }
          
          }}
        >
          {({ errors, values, setFieldValue, isSubmitting }) => (
            <Form>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Template Name:</label>
                    <input type="text" name="template_name" className="form-control mb-0" value={values.template_name} onChange={(e) => setFieldValue('template_name', e.target.value)} />
                    <ErrorMessage name="template_name">
                      {(msg) => (
                        <div className="error" style={{ color: "red" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Template Title (Header)</label>                   
                    <input type="text" name="template_title_header" className="form-control mb-0" maxLength="50" value={values.template_title_header} placeholder="Example: Amazing boldfaced list title" onChange={(e) => setFieldValue('template_title_header', e.target.value)} />
                    <ErrorMessage name="template_title_header">
                      {(msg) => (
                        <div className="error" style={{ color: "red" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-row mb-1">
                    <label className="col-6">Message:</label>
                    <div className="col-6">
                      <button type="button" data-toggle="modal" data-target="#help-modal" className="btn btn-neutral btn-sm float-right"><i className="fas fas fa-code"></i> Shortcodes</button>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea className="form-control h-200" name="template_message" maxLength="1000" value={values.template_message} onChange={(e) => setFieldValue('template_message', e.target.value)}></textarea>
                    <ErrorMessage name="template_message">
                      {(msg) => (
                        <div className="error" style={{ color: "red" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Template Footer Text</label>
                    <input type="text" name="template_footertext" className="form-control mb-0" maxLength="50" value={values.template_footertext} onChange={(e) => setFieldValue('template_footertext', e.target.value)} />
                    <ErrorMessage name="template_footertext">
                      {(msg) => (
                        <div className="error" style={{ color: "red" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Button Text for select option</label>
                    <input type="text" name="template_buttontext_forselection" className="form-control mb-0" maxLength="50" value={values.template_buttontext_forselection} onChange={(e) => setFieldValue('template_buttontext_forselection', e.target.value)} />
                    <ErrorMessage name="template_buttontext_forselection">
                      {(msg) => (
                        <div className="error" style={{ color: "red" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="list-option-area" >
                    <div className="row">
                      <div className="col-6">
                        <h4 className="mt-2">List Options</h4>
                      </div>
                      {values.template_listoption &&
                        values.template_listoption.map((items_section, index) => (
                          <>
                            <div
                              key={`sectionlistoptions_${index}${items_section.section_id}`}
                              className="card w-100 my-3 mx-1"
                            >
                              <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <h5>List {index + 1}</h5>
                                  </div>
                                  <div>
                                    {values.template_listoption &&
                                      values.template_listoption.length > 1 &&
                                      index != 0 && (
                                        <button
                                          type="button"
                                          className="btn-sm btn-danger text-nowrap px-1"
                                          onClick={(e) => deleteForm(values, index)}
                                        >
                                          <CancelIcon fontSize="18" />
                                          <span>Delete</span>
                                        </button>
                                      )}
                                  </div>
                                </div>

                                <div>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      List Section Title
                                    </label>
                                    <Field name={`sections[${index}].title`}>
                                      {({ field, form }) => (
                                        <input
                                          type="text"
                                          className="form-control m-0"
                                          placeholder="Template Name"
                                          name={`template_listoption[${index}].title`}
                                          value={values.template_listoption[index].title}
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            setFieldValue(
                                              `template_listoption[${index}].title`,
                                              value
                                            );
                                          }}
                                        />
                                      )}
                                    </Field>
                                    <ErrorMessage name={`template_listoption[${index}].title`}>
                                      {(msg) => (
                                        <div
                                          className="error"
                                          style={{ color: "red" }}
                                        >
                                          {msg}
                                        </div>
                                      )}
                                    </ErrorMessage>
                                  </div>
                                </div>
                                {items_section.rows.map((section_row, rowindex) => (
                                  <>
                                    <div key={`sectionrows_${index}${rowindex}`}>
                                      <div className="row">
                                        <div className="col-sm-5">
                                          <div className="mb-3">
                                            <label className="form-label">
                                              List Value Name
                                            </label>
                                            <Field
                                              name={`template_listoption[${index}].rows[${rowindex}].listvaluename`}
                                            >
                                              {({ field, form }) => (
                                                <input
                                                  type="text"
                                                  className="form-control m-0"
                                                  placeholder="Enter List Value Name"
                                                  name={`template_listoption[${index}].rows[${rowindex}].listvaluename`}
                                                  value={
                                                    values.template_listoption[index].rows[
                                                      rowindex
                                                    ].listvaluename
                                                  }
                                                  onChange={(e) => {
                                                    const value = e.target.value;
                                                    setFieldValue(
                                                      `template_listoption[${index}].rows[${rowindex}].listvaluename`,
                                                      value
                                                    );
                                                  }}
                                                />
                                              )}
                                            </Field>
                                            <ErrorMessage
                                              name={`template_listoption[${index}].rows[${rowindex}].listvaluename`}
                                            >
                                              {(msg) => (
                                                <div
                                                  className="error"
                                                  style={{ color: "red" }}
                                                >
                                                  {msg}
                                                </div>
                                              )}
                                            </ErrorMessage>
                                          </div>
                                        </div>
                                        <div className="col-sm-5">
                                          <div className="mb-3">
                                            <label className="form-label">
                                              List Value Description
                                            </label>
                                            <Field
                                              name={`template_listoption[${index}].rows[${rowindex}].description`}
                                            >
                                              {({ field, form }) => (
                                                <input
                                                  type="text"
                                                  className="form-control m-0"
                                                  placeholder="Enter List Value Description"
                                                  name={`template_listoption[${index}].rows[${rowindex}].description`}
                                                  value={
                                                    values.template_listoption[index].rows[
                                                      rowindex
                                                    ].description
                                                  }
                                                  onChange={(e) => {
                                                    const value = e.target.value;
                                                    setFieldValue(
                                                      `template_listoption[${index}].rows[${rowindex}].description`,
                                                      value
                                                    );
                                                  }}
                                                />
                                              )}
                                            </Field>
                                            <ErrorMessage
                                              name={`template_listoption[${index}].rows[${rowindex}].description`}
                                            >
                                              {(msg) => (
                                                <div
                                                  className="error"
                                                  style={{ color: "red" }}
                                                >
                                                  {msg}
                                                </div>
                                              )}
                                            </ErrorMessage>
                                          </div>
                                        </div>
                                        {items_section.rows &&
                                          items_section.rows.length > 1 &&
                                          rowindex != 0 && (
                                            <div className="col-sm-2">
                                              <label className="form-label">
                                                Delete
                                              </label>
                                              <br />
                                              <button
                                                className="btn-sm btn-danger"
                                                onClick={(e) =>
                                                  deleteSectionMoreRowItem(
                                                    values,
                                                    index,
                                                    setFieldValue,
                                                    rowindex
                                                  )
                                                }
                                                type="button"
                                              >
                                                {" "}
                                                <CancelIcon fontSize="18" />
                                                Item
                                              </button>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </>
                                ))}
                                <div className="d-flex justify-content-end mb-2 align-item-center">
                                  <button
                                    className="btn-sm btn-light-primary"
                                    onClick={(e) =>
                                      increaseSectionMoreRowItem(
                                        values,
                                        index,
                                        setFieldValue,
                                        items_section.rows.length
                                      )
                                    }
                                    type="button"
                                  >
                                    {" "}
                                    <AddIcon />
                                    Add More Item
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      <div className="d-flex justify-content-end mb-2 align-item-center">
                        <button
                          className="btn-sm btn-light-primary"
                          onClick={(e) => increaseCount(values, setFieldValue)}
                          type="button"
                        >
                          {" "}
                          <AddIcon />
                          Add More Card
                        </button>
                      </div>
                    </div>
                    <div className="list-area">
                      {/* {renderCards()} */}
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <button type="submit" className="btn btn-outline-primary submit-button float-left">{props.isedit ? 'Update Template': 'Save Template'}</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Fragment>
    </div>
  );
}

export default ListMessage;
