import React from "react";
import Sidebar from "./sidebar/Sidebar";
import Header  from "./header/Header";
import BreadCrumb  from "../../components/BreadCrumb";
import { Outlet } from 'react-router-dom';

// import navigation from 'menu-items';

const MainLayout = () => {
  return (
    <div>
      <Sidebar />
      <main id="mainContainer" className="">
        <div className="mx-1 position-relative">
          <div className="profile position-relative p-4">
            <Header />
          </div>
          <BreadCrumb />
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default MainLayout;
