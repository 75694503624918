import { Fragment, useEffect, useState } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col,
    Alert,
    Label, Table as ReactTable, Badge
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ContactListService from "../../services/contactlist/ContactList";
import Select from "react-select";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from 'react-redux';
import BlackList from "../../services/blacklist/Blacklist";
const AddContact = (props) => {
    const [centeredModal, setCenteredModal] = useState(false);
    const [GroupListData, setGroupListData] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState();
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userData")));
    const sessionId = localStorage.getItem("sessionId")
    const currentUserData = useSelector(state => state.profile.profile);
    const closeModal = () => {
        setCenteredModal(false)
    }
    const searchSchema = Yup.object().shape({
        Username: Yup.string().required("Cannot be empty"),
        whatsappno: Yup.number().required("Cannot be empty"),
    });

    return (
        <div className="vertically-centered-modal">
            <button className='ms-3 btn btn-sm btn-neutral rounded-pill' onClick={() => { setCenteredModal(true) }}>Add Contact</button>

            <Modal
                isOpen={centeredModal}
                toggle={closeModal}
                className="modal-dialog-centered modal-lg"
                backdrop={true}
            >
                <ModalHeader toggle={closeModal}>
                    <h3>Add Contact</h3>
                </ModalHeader>
                <Formik
                    validationSchema={searchSchema}
                    initialValues={{
                        Username: '',
                        whatsappno: ''
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        console.log(values, "values in creat contact form!!!!")
                        let data = {
                            contact_name: values.Username,
                            whatsappno: values.whatsappno,
                            user_id: currentUserData?.currentUserData?.result?.user_id
                        };
                        console.log(data, "data in create contact!!!!!")
                        const addParticipant = new BlackList();
                        addParticipant
                            .addcontactblackbyuserid(data)
                            .then((response) => {
                                // console.log(response,"response in add participant in group!!!!")
                                if (response.isSuccess === true) {
                                    toast.success(response?.message);
                                    closeModal();
                                    props.getAllContacts();
                                } else {
                                    toast.error(response?.message);
                                }
                                resetForm();
                                setSubmitting(false)
                            })
                            .catch((error) => {
                                console.error("Error fetching user profile:", error);
                            });
                    }
                    }

                >
                    {({ values, setFieldValue, isSubmitting }) =>
                        <Form>
                            <Fragment>
                                <ModalBody>
                                    <Row className="align-items-center ">
                                        <Col>
                                            <Field name="Username">
                                                {({ field, form }) => (
                                                    <div className="mb-3">
                                                        <label for="exampleInputPassword1" className="form-label">Contact Name</label>
                                                        <input type="text" className="form-control mb-0" id="Username" name="Username" value={values.Username} onChange={(e) => setFieldValue('Username', e.target.value)} />
                                                        <ErrorMessage name="Username">
                                                            {(msg) => (
                                                                <div className="error" style={{ color: "red" }}>
                                                                    {msg}
                                                                </div>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                )}
                                            </Field>

                                        </Col>
                                    </Row>
                                    <Row className="align-items-center ">
                                        <Col>
                                            <Field name="whatsappno">
                                                {({ field, form }) => (
                                                    <div className="mb-3">
                                                        <label for="exampleInputPassword1" className="form-label">Whatsapp Number</label>
                                                        <input type="number" className="form-control mb-0" placeholder='Enter Phone number with country code' id="whatsappno" name="whatsappno" value={values.whatsappno} onChange={(e) => setFieldValue('whatsappno', e.target.value)} />
                                                        <ErrorMessage name="whatsappno">
                                                            {(msg) => (
                                                                <div className="error" style={{ color: "red" }}>
                                                                    {msg}
                                                                </div>
                                                            )}
                                                        </ErrorMessage>
                                                    </div>
                                                )}
                                            </Field>

                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row className="align-items-center ">
                                        <Col md="2" sm="12">
                                            <Button color="primary" type="submit" disabled={isSubmitting}>
                                                {isSubmitting ? 'Creating...' : 'Create Now'}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <hr />
                                </ModalBody>
                            </Fragment>
                        </Form>
                    }
                </Formik>

            </Modal>
        </div>
    );
};

export default AddContact;
