import React from 'react';
import { lazy, Suspense } from "react";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './redux/store';
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from "react-hot-toast";
import 'bootstrap/dist/css/bootstrap.min.css';

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <BrowserRouter>
  //   <Provider store={store}>
  //     <App />
  //   </Provider>
  // </BrowserRouter>
  <BrowserRouter>
  <Provider store={store}>
    <Suspense >
        <LazyApp />
        <Toaster
          position="top-right"
          toastOptions={{ className: "react-hot-toast" }}
        />
    </Suspense>
  </Provider>
</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
