import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    getSessionIdState: {
        isFetching: false,
        isSuccess: false,
        isError: false,
        sessionId: "",
    }
};

export const getSessionId = createAsyncThunk(
    'getSessionId/getSessionIdSlice',
    async () => {
        const url = `${process.env.REACT_APP_NODE_URL}/api/login/get-sessionId`;
        console.log('get session id', url);
        const config = {
            headers: {
                token: localStorage.getItem('isToken'),
            },
        };
        console.log(config);

        const response = await axios.get(url, config);
        console.log(response.data, 'session id');
        return response.data;
    },
);

const getSessionIdSlice = createSlice({
    name: 'getSessionIdState',
    initialState,
    reducers: {
        clearSessionId: state => {
            state.getSessionIdState.isFetching = false;
            state.getSessionIdState.isSuccess = false;
            state.getSessionIdState.isError = false;
        },
    },

    extraReducers: builder => {
        builder.addCase(getSessionId.pending, state => {
            state.getSessionIdState.isFetching = true;
            state.getSessionIdState.isSuccess = false;
        });
        builder.addCase(getSessionId.fulfilled, (state, action) => {
            console.log("Fulfilled action payload:", action.payload);
            state.getSessionIdState.isFetching = false;
            state.getSessionIdState.isError = false;
            state.getSessionIdState.isSuccess = true;
            state.getSessionIdState.sessionId = action.payload;
        });
        builder.addCase(getSessionId.rejected, (state, action) => {
            state.getSessionIdState.isError = true;
            state.getSessionIdState.isFetching = false;
        });
    },
});

export const { clearSessionId } = getSessionIdSlice.actions;
export default getSessionIdSlice.reducer;
