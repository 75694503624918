import { lazy } from 'react';

// project import
import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout';
import ManageUserRoles from '../pages/managerole/ManageUserRoles';

// render - dashboard
const Dashboard = Loadable(lazy(() => import('../pages/dashboard/index')));
const MyDevices = Loadable(lazy(() => import('../pages/MyDevices/MyDevices')));
const Subscription = Loadable(lazy(() => import('../pages/Subscription/Subscription')));
const Roles = Loadable(lazy(() => import('../pages/administration/Roles')));
const Users = Loadable(lazy(() => import('../pages/administration/Users')));
const Configuration = Loadable(lazy(() => import('../pages/administration/Configuration')));
const MyApps = Loadable(lazy(() => import('../pages/myapps/MyApps')));
const TemplatePage = Loadable(lazy(() => import('../pages/myapps/TemplatePage')));
// const CreateTemplate = Loadable(lazy(() => import('../pages/mytemplate/CreateTemplate')));
const CreateTemplate = Loadable(lazy(() => import('../pages/mytemplate/CreateTemplateModify')));
const AllowUserPlan = Loadable(lazy(() => import('../pages/administration/AllowUserPlan')));
const AddRole = Loadable(lazy(() => import('../pages/administration/AddRole')));
const EditRole = Loadable(lazy(() => import('../pages/administration/EditRole')));
const SingleSend = Loadable(lazy(() => import('../pages/singlesend/SingleSend')));
const Chatbot = Loadable(lazy(() => import('../pages/chatbot/Chatbot')));
const GroupSend = Loadable(lazy(() => import('../pages/groupsendsmessage/GroupSend')));
const ContactsBook = Loadable(lazy(() => import('../pages/contactbook/ContactsBook')));
const MyTemplates = Loadable(lazy(() => import('../pages/mytemplate/MyTemplates')));
const BulkMessage = Loadable(lazy(() => import('../pages/bulkmessage/BulkMessage')));
const SendBulkMessage = Loadable(lazy(() => import('../pages/bulkmessage/SendBulkMessage')));
const ScheduledMessage = Loadable(lazy(() => import('../pages/scheduledmessage/ScheduledMessage')));
const CreateScheduled = Loadable(lazy(() => import('../pages/scheduledmessage/CreateScheduled')));
const MessageLogs = Loadable(lazy(() => import('../pages/messagelogs/MessageLogs')));
const HelpSupport = Loadable(lazy(() => import('../pages/helpsupport/HelpSupport')));
const ConversionPage = Loadable(lazy(() => import('../pages/helpsupport/ConversionPage')));
const CreatePage = Loadable(lazy(() => import('../pages/helpsupport/CreatePage')));
const UserProfile = Loadable(lazy(() => import('../pages/profilesetting/UserProfile')));
const CreateDevice = Loadable(lazy(() => import('../pages/MyDevices/CreateDevice')));
const Scanner = Loadable(lazy(() => import('../pages/MyDevices/Scanner')));
const CreateContactsGroup = Loadable(lazy(() => import('../pages/contactbook/CreateContactsGroup')));
const ContactGroupSend = Loadable(lazy(() => import('../pages/contactbookgroup/ContactGroupSend')));
const GroupContactList = Loadable(lazy(() => import('../pages/contactbook/GroupContactList')));
const UserOverView = Loadable(lazy(() => import('../pages/dashboard/components/UserOverView')));
const AdminDashboard = Loadable(lazy(() => import('../pages/dashboard/components/AdminDashboard')))
const ReceivedMessages = Loadable(lazy(() => import('../pages/messagelogs/ReceivedMessages')))
const ExtractNumbers = Loadable(lazy(() => import('../pages/ExtractNumbers/ExtractNumbers')))
const Blacklist = Loadable(lazy(() => import('../pages/Blacklist/Blacklist')))
const DragAndDrop = Loadable(lazy(() => import('../pages/dnd/DragAndDrop')))
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Dashboard />
    },
    {
      path: '/admindashboard',
      element: <AdminDashboard />
    },
    {
      path: '/home',
      element: <Dashboard />
    },
    {
      path: '/mydevices',
      element: <MyDevices />
    },
    {
      path: '/create',
      element: <CreateDevice />
    },
    {
      path: '/scanner',
      element: <Scanner />
    },
    {
      path: '/chatbot',
      element: <Chatbot />
    },
    {
      path: '/groupsendmsg',
      element: <GroupSend />
    },
    {
      path: '/contactlist',
      element: <GroupContactList />
    },
    {
      path: '/singlesend',
      element: <SingleSend />
    },
    {
      path: '/myapps',
      element: <MyApps />
    },

    {
      path: '/integration',
      element: <TemplatePage />
    },

    {
      path: '/createtemplate',
      element: <CreateTemplate />
    },

    {
      path: '/contactbook',
      element: <ContactsBook />
    },
    {
      path: '/mytemplate',
      element: <MyTemplates />
    },
    {
      path: '/bulkmessage',
      element: <BulkMessage />
    },
    {
      path: '/sendbulkmessage',
      element: <SendBulkMessage />
    },
    // {
    //   path: '/singlesend',
    //   element: <DragAndDrop />
    // },
    {
      path: '/scheduledmessage',
      element: <ScheduledMessage />
    },
    {
      path: '/createscheduled',
      element: <CreateScheduled />
    },


    {
      path: '/messagelogs',
      element: <MessageLogs />
    },

    {
      path: '/receivedmessages',
      element: <ReceivedMessages />
    },
    {
      path: '/roles',
      element: <Roles />
    },
    {
      path: '/addrole',
      element: <AddRole />
    },
    {
      path: '/editrole',
      element: <EditRole />
    },
    {
      path: '/users',
      element: <Users />
    },
    {
      path: '/configuration',
      element: <Configuration />
    },
    {
      path: '/subscription',
      element: <Subscription />
    },
    {
      path: '/help_support',
      element: <HelpSupport />
    },

    {
      path: '/convarsionPage',
      element: <ConversionPage />
    },

    {
      path: '/createpage',
      element: <CreatePage />
    },
    {
      path: '/profilesetting',
      element: <UserProfile />
    },
    {
      path: '/subscription',
      element: <Subscription />
    },
    {
      path: '/contactgroup',
      element: <CreateContactsGroup />
    },
    {
      path: '/allowsubscription',
      element: <AllowUserPlan />
    },
    {
      path: '/manageroles',
      element: <ManageUserRoles />
    },
    {
      path: '/contactgroupsend',
      element: <ContactGroupSend />
    },
    {
      path: '/overview',
      element: <UserOverView />
    },
    {
      path: '/extractNumbers',
      element: <ExtractNumbers />
    },
    {
      path: '/blacklist',
      element: <Blacklist />
    },
  ]
};

export default MainRoutes;
