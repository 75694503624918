import React, { useRef, useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import DevicesIcon from "@mui/icons-material/Devices";
import SendIcon from "@mui/icons-material/Send";
import ChatIcon from "@mui/icons-material/Chat";
import AppsIcon from "@mui/icons-material/Apps";
import BulkMessageIcon from "@mui/icons-material/Description";
import ScheduledIcon from "@mui/icons-material/AccessTime";
import MessageLogIcon from "@mui/icons-material/History";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import PeopleIcon from "@mui/icons-material/People";
import SubscriptionIcon from "@mui/icons-material/Subscriptions";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpIcon from "@mui/icons-material/Help";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { ImAddressBook } from "react-icons/im";
import { ImFileEmpty } from "react-icons/im";
import "../../../Styles/Sidebar.css";
import AppBlockingIcon from '@mui/icons-material/AppBlocking';
import NumbersIcon from '@mui/icons-material/Numbers';


import DeviceService from "../../../services/devices/DeviceService";
import { setDeviceConnectionMessage } from "../../../redux/slices/checkDeviceIsConnectionSlice";
const hrStyle = {
  margin: "20px 0",
  border: "none",
  borderBottom: "2px solid #ccc",
};

const Sidebar = () => {
  const dispatch = useDispatch();
  const bottomDiv = useRef();
  const adminAsideRef = useRef();
  const toggleBtnRef = useRef();
  const [bottomDivState, setBottomDivState] = useState(false);
  const profileDataState = useSelector((state) => state.profile.profile);
  const navigate = useNavigate();
  const [deviceList, setDeviceList] = useState([]);
  const checkDeviceDataState = useSelector(
    (state) => state.checkDeviceIsConnectionState.message
  );
  const checkTabGrant = useCallback((pathArr) => {
    let success = false;
    const roleArray = localStorage.getItem("roleArray");
    if (!roleArray) {
      return;
    }
    const checkList = roleArray.split(",");
    pathArr.forEach((element) => {
      if (checkList.includes(element)) {
        success = true;
      }
    });
    return success;
  });

  function toggleHandler() {
    if (document.getElementById("root").classList.contains("toggleSideBar")) {
      document.getElementById("root").classList.remove("toggleSideBar");
    } else {
      document.getElementById("root").classList.add("toggleSideBar");
    }
  }

  useEffect(() => {
    if (profileDataState && profileDataState.currentUserData.isSuccess) {
      // console.log(profileDataState.currentUserData.result, "profileDataState");
      let roleArray = [];
      Array.isArray(
        profileDataState.currentUserData.result.features.filter((i) => {

          roleArray.push(i.feature);
        })
      );
      localStorage.setItem("roleArray", roleArray);
      localStorage.setItem(
        "userData",
        JSON.stringify(profileDataState.currentUserData.result)
      );
    }
  }, [profileDataState]);

  const getallActiveDevices = () => {
    const deviceService = new DeviceService();
    deviceService
      .getAllActiveDevices()
      .then((response) => {
        if (response.isSuccess === true) {
          setDeviceList(response.result);
          dispatch(setDeviceConnectionMessage(""));
        }
      })
      .catch((error) => {
        console.error("Error fetching user profile:", error);
      });
  };
  useEffect(() => {
    getallActiveDevices();
  }, []);
  useEffect(() => {
    if (checkDeviceDataState == "Connected Device") {
      getallActiveDevices();
    }
  }, [checkDeviceDataState]);

  return (
    <aside className="asideNav">
      <button onClick={toggleHandler} ref={toggleBtnRef} className="toggleBtn">
        <main className="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="headSvg bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
        </main>
      </button>
      <main className="asideMain">
        <section className="outerSection">
          <div className="my-3">
            <div className="balkrushna_image">
              <img src="/Balkrushna_logo.svg" alt="Balkrushna Technology" />
            </div>
            <div className="balkrushna_logo">
              <img src="/favicon.png" alt="Balkrushna Technology" />
            </div>
          </div>
          <ul id="accordionExample" className="outUl mt-4">
            {checkTabGrant(["dashboard"]) && (
              <li
                className="inLi sidemenu"
                onClick={(e) => {
                  navigate("/home");
                }}
              >
                <NavLink
                  className="headBtn liststyle"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#sale-collapse"
                  aria-expanded="false"
                  aria-controls="sale-collapse"
                  to="/home"
                >
                  <DashboardIcon className="liststyle-icon" />
                  <span>Dashboard</span>
                </NavLink>
              </li>
            )}

            {checkTabGrant(["mydevices"]) && (
              <li
                className="inLi sidemenu"
                onClick={(e) => {
                  navigate("/mydevices");
                }}
              >
                <NavLink
                  className="headBtn liststyle"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#sale-collapse"
                  aria-expanded="false"
                  aria-controls="sale-collapse"
                  to="/mydevices"
                >
                  <DevicesIcon className="liststyle-icon" />
                  <span>My Devices</span>
                </NavLink>
              </li>
            )}
            {checkTabGrant(["single"]) && (
              <li
                className="inLi sidemenu"
                onClick={(e) => {
                  navigate("/singlesend");
                }}
              >
                <NavLink
                  className="headBtn liststyle"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#sale-collapse"
                  aria-expanded="false"
                  aria-controls="sale-collapse"
                  to="/singlesend"
                >
                  <SendIcon className="liststyle-icon" />
                  {/* <span>Single Send</span> */}
                  <span>Send Messages</span>
                </NavLink>
              </li>
            )}
            {/* {checkTabGrant(["single"]) && (
              <li
                className="inLi sidemenu"
                onClick={(e) => {
                  navigate("/contactgroupsend");
                }}
              >
                <NavLink
                  className="headBtn liststyle"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#sale-collapse"
                  aria-expanded="false"
                  aria-controls="sale-collapse"
                  to="/contactgroupsend"
                >
                  <SendIcon className="liststyle-icon" />
                  <span>Contact Book Group</span>
                </NavLink>
              </li>
            )} */}
            {checkTabGrant(["group"]) && (
              <li
                className="inLi sidemenu"
                onClick={(e) => {
                  navigate("/groupsendmsg");
                }}
              >
                <NavLink
                  className="headBtn liststyle"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#sale-collapse"
                  aria-expanded="false"
                  aria-controls="sale-collapse"
                  to="/groupsendmsg"
                >
                  <SendIcon className="liststyle-icon" />
                  <span> Group Send</span>
                </NavLink>
              </li>
            )}

            {checkTabGrant(["chatbot"]) && (
              <li
                className="inLi sidemenu"
                onClick={(e) => {
                  navigate("/chatbot");
                }}
              >
                <NavLink
                  className="headBtn liststyle"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#sale-collapse"
                  aria-expanded="false"
                  aria-controls="sale-collapse"
                  to="/chatbot"
                >
                  <ChatIcon className="liststyle-icon" />
                  <span>Chatbot (Auto Reply)</span>
                </NavLink>
              </li>
            )}

            {deviceList.length > 0 && checkTabGrant(["myapps"]) && (
              <li
                className="inLi sidemenu"
                onClick={(e) => {
                  navigate("/myapps");
                }}
              >
                <NavLink
                  className="headBtn liststyle"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#sale-collapse"
                  aria-expanded="false"
                  aria-controls="sale-collapse"
                  to="/myapps"
                >
                  <AppsIcon className="liststyle-icon" />
                  <span>My Apps</span>
                </NavLink>
              </li>
            )}
            {checkTabGrant(["contactbook"]) && (
              <li
                className="inLi sidemenu"
                onClick={(e) => {
                  navigate("/contactbook");
                }}
              >
                <NavLink
                  className="headBtn liststyle"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#sale-collapse"
                  aria-expanded="false"
                  aria-controls="sale-collapse"
                  to="/contactbook"
                >
                  <ImAddressBook className="liststyle-icon" />
                  <span>Contacts Book</span>
                </NavLink>
              </li>
            )}

            {checkTabGrant(["templates"]) && (
              <li
                className="inLi sidemenu"
                onClick={(e) => {
                  navigate("/mytemplate");
                }}
              >
                <NavLink
                  className="headBtn liststyle"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#sale-collapse"
                  aria-expanded="false"
                  aria-controls="sale-collapse"
                  to="/mytemplate"
                >
                  <ImFileEmpty className="liststyle-icon" />
                  <span>My Templates</span>
                </NavLink>
              </li>
            )}

            {checkTabGrant(["bulkmessage"]) && (
              <li
                className="inLi sidemenu"
                onClick={(e) => {
                  navigate("/bulkmessage");
                }}
              >
                <NavLink
                  className="headBtn liststyle"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#sale-collapse"
                  aria-expanded="false"
                  aria-controls="sale-collapse"
                  to="/bulkmessage"
                >
                  <BulkMessageIcon className="liststyle-icon" />
                  <span>Send Bulk Messages</span>
                </NavLink>
              </li>
            )}

            {checkTabGrant(["scheduled"]) && (
              <li
                className="inLi sidemenu"
                onClick={(e) => {
                  navigate("/scheduledmessage");
                }}
              >
                <NavLink
                  className="headBtn liststyle"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#sale-collapse"
                  aria-expanded="false"
                  aria-controls="sale-collapse"
                  to="/scheduledmessage"
                >
                  <ScheduledIcon className="liststyle-icon" />
                  <span>Scheduled Message</span>
                </NavLink>
              </li>
            )}
            {checkTabGrant(["log"]) && (
              <li
                className="inLi sidemenu"
                onClick={(e) => {
                  navigate("/messagelogs");
                }}
              >
                <NavLink
                  className="headBtn liststyle"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#sale-collapse"
                  aria-expanded="false"
                  aria-controls="sale-collapse"
                  to="/messagelogs"
                >
                  <MessageLogIcon className="liststyle-icon" />
                  <span>Message Log</span>
                </NavLink>
              </li>
            )}
            {checkTabGrant(["extractNumbers"]) && (
              <li
                className="inLi sidemenu"
                onClick={(e) => {
                  navigate("/extractNumbers");
                }}
              >
                <NavLink
                  className="headBtn liststyle"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#sale-collapse"
                  aria-expanded="false"
                  aria-controls="sale-collapse"
                  to="/extractNumbers"
                >
                  <NumbersIcon className="liststyle-icon" />
                  <span>Extract Numbers</span>
                </NavLink>
              </li>
            )}
            {checkTabGrant(["blacklist"]) && (
              <li
                className="inLi sidemenu"
                onClick={(e) => {
                  navigate("/blacklist");
                }}
              >
                <NavLink
                  className="headBtn liststyle"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#sale-collapse"
                  aria-expanded="false"
                  aria-controls="sale-collapse"
                  to="/blacklist"
                >
                  <AppBlockingIcon className="liststyle-icon" />
                  <span>Black List Numbers</span>
                </NavLink>
              </li>
            )}
            {/* {checkTabGrant(["log"]) && (
              <li
                className="inLi sidemenu"
                onClick={(e) => {
                  navigate("/receivedmessages");
                }}
              >
                <NavLink
                  className="headBtn liststyle"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#sale-collapse"
                  aria-expanded="false"
                  aria-controls="sale-collapse"
                  to="/receivedmessages"
                >
                  <MessageLogIcon className="liststyle-icon" />
                  <span>Received Messages</span>
                </NavLink>
              </li>
            )} */}
            {checkTabGrant(["administration"]) && (
              <>
                <hr style={hrStyle} />
                <div>
                  <Accordion
                    style={{ boxShadow: "none", backgroundColor: "#f5f5f5" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography>
                        <div className="d-flex justify-content-center align-items-center">
                          <SettingsIcon className="liststyle-icon" />
                          <span> Administration</span>
                        </div>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        {checkTabGrant(["admindashboard"]) && (
                          <li
                            className="inLi sidemenu"
                            onClick={(e) => {
                              navigate("/admindashboard");
                            }}
                          >
                            <NavLink
                              className="headBtn liststyle"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#sale-collapse"
                              aria-expanded="false"
                              aria-controls="sale-collapse"
                              to="/roles"
                            >
                              <DashboardIcon className="liststyle-icon" />
                              {/* <ArrowForwardIcon /> */}
                              <span>Dashboard</span>
                            </NavLink>
                          </li>
                        )}
                        {checkTabGrant(["roles"]) && (
                          <li
                            className="inLi sidemenu"
                            onClick={(e) => {
                              navigate("/roles");
                            }}
                          >
                            <NavLink
                              className="headBtn liststyle"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#sale-collapse"
                              aria-expanded="false"
                              aria-controls="sale-collapse"
                              to="/roles"
                            >
                              <SupervisedUserCircleIcon className="liststyle-icon" />
                              {/* <ArrowForwardIcon /> */}
                              <span>Roles</span>
                            </NavLink>
                          </li>
                        )}
                        {checkTabGrant(["users"]) && (
                          <li
                            className="inLi sidemenu"
                            onClick={(e) => {
                              navigate("/users");
                            }}
                          >
                            <NavLink
                              className="headBtn liststyle"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#sale-collapse"
                              aria-expanded="false"
                              aria-controls="sale-collapse"
                              to="/users"
                            >
                              <PeopleIcon className="liststyle-icon" />
                              {/* <ArrowForwardIcon /> */}
                              <span>Users</span>
                            </NavLink>
                          </li>
                        )}
                        {checkTabGrant(["single"]) && (
                          <li
                            className="inLi sidemenu"
                            onClick={(e) => {
                              navigate("/configuration");
                            }}
                          >
                            <NavLink
                              className="headBtn liststyle"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#sale-collapse"
                              aria-expanded="false"
                              aria-controls="sale-collapse"
                              to="/configuration"
                            >
                              <SettingsApplicationsIcon className="liststyle-icon" />
                              {/* <ArrowForwardIcon /> */}
                              <span>Configuration</span>
                            </NavLink>
                          </li>
                        )}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </>
            )}

            <hr style={hrStyle} />
            {checkTabGrant(["profilesetting"]) && (
              <li
                className="inLi sidemenu"
                onClick={(e) => {
                  navigate("/subscription");
                }}
              >
                <NavLink
                  className="headBtn liststyle"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#sale-collapse"
                  aria-expanded="false"
                  aria-controls="sale-collapse"
                  to="/subscription"
                >
                  <SubscriptionIcon className="liststyle-icon" />
                  <span>Subscription</span>
                </NavLink>
              </li>
            )}

            {checkTabGrant(["profilesetting"]) && (
              <li
                className="inLi sidemenu"
                onClick={(e) => {
                  navigate("/help_support");
                }}
              >
                <NavLink
                  className="headBtn liststyle"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#sale-collapse"
                  aria-expanded="false"
                  aria-controls="sale-collapse"
                  to="/help_support"
                >
                  <HelpIcon className="liststyle-icon" />
                  <span>Help & Support</span>
                </NavLink>
              </li>
            )}

            {checkTabGrant(["profilesetting"]) && (
              <li
                className="inLi sidemenu"
                onClick={(e) => {
                  navigate("/profilesetting");
                }}
              >
                <NavLink
                  className="headBtn liststyle"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#sale-collapse"
                  aria-expanded="false"
                  aria-controls="sale-collapse"
                  to="/profilesetting"
                >
                  <SettingsIcon className="liststyle-icon" />
                  <span>Profile Settings</span>
                </NavLink>
              </li>
            )}
          </ul>
        </section>
      </main>
    </aside>
  );
};

export default Sidebar;
