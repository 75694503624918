import React, { useState, useEffect } from "react";
import backgroundImg from "../images/crmcover.jpg";
import handshake from "../images/handshake.png";
import eyeIcon from "../images/view.png";
import eyeIconClose from "../images/hide.png";
import "../Styles/Login.css";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import Dashboard from "./Dashboard";
import { useDispatch } from "react-redux";
import { getProfileData } from "../redux/slices/userProfileDataSlice";

const Login = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  useEffect(() => {
    localStorage.clear();
  }, []);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };
  const handleSubmit = async () => {
    try {
      const url = `${process.env.REACT_APP_NODE_URL}/api/login`;
      const data = {
        email: email,
        password: password,
      };
      const response = await axios.post(url, data);

      if (response.data && response.data.isSuccess) {
        console.log(response.data.isToken, "isToken");
        localStorage.setItem("isToken", response.data.isToken);
        dispatch(getProfileData());
        navigate("/home");
      } else {
        console.log("Login not successful...");
      }
    } catch (error) {
      console.error("Error during login:", error);
      alert("An error occurred during login");
    }
  };

  return (
    <div
      className="container-fluid"
      style={{ backgroundImage: `url(${backgroundImg})` }}
    >
      <div className="row">
        <div className="col-md-4 d-none d-md-flex d-flex align-items-center justify-content-center inner-cover cover-bg">
          <div>
            <div className="d-flex align-items-center justify-content-center inner-logo">
              <img src={handshake} alt="Logo" className="logo" />
            </div>
            <h2 className="inner-welcome">
              Welcome to Customer Relationship Management System
            </h2>
            <div className="horizontal-line"></div>
            <h5 className="tag-line"></h5>
            <div>
              <div className="d-flex align-items-center justify-content-center inner-logo"></div>
              <div className="d-flex align-items-center justify-content-center">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-8 d-flex align-items-center justify-content-center inner-signin-cover signin-cover-bg">
          <div className="container mx-4">
            <h2 className="sign-in-heading">Login</h2>
            <div className="line"></div>
            <p className="welcome-message">Welcome Back! Please Sign In.</p>

            <div className="">
              <div className=" mt-3 p-4">
                <section className="mb-3">
                  <label htmlFor="username" className="form-label">
                    email
                  </label>
                  <input
                    className="form-control custom-input"
                    onChange={(e) => setemail(e.target.value)}
                    onKeyDown={handleKeyDown}
                    type="text"
                    id="username"
                    name="username"
                    placeholder="Enter Email/Mobile Number"
                  />
                </section>
                <section className="mb-3">
                  <label htmlFor="password" className="form-label login-label">
                    Password
                  </label>
                  <div className="input-group">
                    <input
                      className="form-control custom-input"
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyDown={handleKeyDown}
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      placeholder="Enter Password"
                    />
                    <span
                      className="input-group-text"
                      id="toggle-password"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <img
                        src={showPassword ? eyeIconClose : eyeIcon}
                        alt="Toggle Password"
                        height={20}
                        width={20}
                      />
                    </span>
                  </div>
                </section>
                <section className="mb-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="rememberMe"
                      name="rememberMe"
                      checked={rememberMe}
                      onChange={() => setRememberMe(!rememberMe)}
                    />
                    <label className="form-check-label" htmlFor="rememberMe">
                      Remember Me
                    </label>
                  </div>
                </section>
                <section className="mb-3">
                  <button
                    className="btn btn-block custom-login-button"
                    style={{ fontSize: "18px" }}
                    onClick={handleSubmit}
                    type="submit"
                  >
                    Login
                  </button>
                </section>
                <section className="mb-3">
                  <button
                    className="btn btn-block custom-forgot-btn"
                    style={{ fontSize: "18px" }}
                    type="button"
                  >
                    Forgot Password
                  </button>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
