import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  deviceSessionId: '',
};

const deviceSessionIdSlice = createSlice({
  name: 'deviceSessionId',
  initialState,
  reducers: {
    setDeviceSessionID: (state, action) => {
      state.deviceSessionId = action.payload;
    },
  },
});

export const { setDeviceSessionID } = deviceSessionIdSlice.actions;
export default deviceSessionIdSlice.reducer;