import { requestToken, requestTokenImage } from '../../api/request_methods';

const API_ROUTE = `${process.env.REACT_APP_NODE_URL}/api`;

class ContactList {
    async getGroupListData(sessionId) {
        try {
            console.log(sessionId, "sessionId!!!!!")
            let action = API_ROUTE + `/contactbook/get-group-list/${sessionId}`
            console.log(action, "service calling!!!!!")
            const response = await requestToken.get(action);
            return response;
        } catch (error) {
            console.error('Error in getContactgroups:', error);
            throw error;
        }
    }

    async addParticipantInGroup(data) {
        try {
            let action = API_ROUTE + '/contactbook/add-participant-ingroup'
            const response = await requestToken.post(action, data);
            return response;
        } catch (error) {

            console.error('Error in save contact:', error);
            throw error;
        }
    }

    async saveContactGroup(data) {
        try {
            let action = API_ROUTE + '/contactbook/add-group'
            const response = await requestToken.post(action, data);
            return response;
        } catch (error) {

            console.error('Error in save group:', error);
            throw error;
        }
    }

    async getGroupListDatabyUser_id(user_id) {
        try {
            let action = API_ROUTE + `/contactbook/get-group-list-byuserid/${user_id}`
            console.log(action, "service calling!!!!!")
            const response = await requestToken.get(action);
            console.log(response, "response in service file get-group-list-byuserid!!!!!")
            return response;
        } catch (error) {
            console.error('Error in getContactgroups:', error);
            throw error;
        }
    }

    async getWhatsAppGroupList(sessionId) {
        try {
            let action = API_ROUTE + `/contactbook/get-whatsapp-group/${sessionId}`
            console.log(action, "whatsappp group")
            const response = await requestToken.get(action);
            console.log(response, "response in service file whatsapp group")
            return response;
        } catch (error) {
            console.error('Error in getContactgroups:', error);
            throw error;
        }
    }

     
    async addcontactbyuserid(data) {
        try {
            let action = API_ROUTE + '/contactbook/add-contact-byuserid'
            const response = await requestToken.post(action, data);
            return response;
        } catch (error) {

            console.error('Error in save contact:', error);
            throw error;
        }
    }

    async importcontactbyuserid(data) {
        try {
            let action = API_ROUTE + '/contactbook/import-contact-byuserid'
            const response = await requestTokenImage.post(action, data);
            return response;
        } catch (error) {

            console.error('Error in save contact:', error);
            throw error;
        }
    }
    async getContactListDatabyUser_id(user_id) {
        try {
            let action = API_ROUTE + `/contactbook/get-contact-list-byuserid/${user_id}`
            console.log(user_id, "helllllllllllllllllllllllooooooooooooooo")
            const response = await requestToken.get(action);
            console.log(response, "response in service file get-contact-list-byuserid!!!!!")
            return response;
        } catch (error) {
            console.error('Error in get-contact-list-byuserid:', error);
            throw error;
        }
    }

    async updatecontactbyuserid(data) {
        try {
            let action = API_ROUTE + '/contactbook/update-contact-byuserid'
            const response = await requestToken.post(action, data);
            return response;
        } catch (error) {

            console.error('Error in save contact:', error);
            throw error;
        }
    }
    async updategroupbyuserid(data) {
        try {
            let action = API_ROUTE + '/contactbook/update-group-byuserid'
            const response = await requestToken.post(action, data);
            return response;
        } catch (error) {

            console.error('Error in save contact:', error);
            throw error;
        }
    }
    async deletecontactbyuserid(idContact_list) {
        try {
            let action = API_ROUTE + `/contactbook/delete-contact-byuserid/${idContact_list}`
            const response = await requestToken.post(action);
            return response;
        } catch (error) {

            console.error('Error in save contact:', error);
            throw error;
        }
    }
    async deletegroupbyuserid(idcontactGroups) {
        try {
            let action = API_ROUTE + `/contactbook/delete-group-byuserid/${idcontactGroups}`
            const response = await requestToken.post(action);
            return response;
        } catch (error) {

            console.error('Error in save contact:', error);
            throw error;
        }
    }

    async getContactBookGroup() {
        try {
            let action = API_ROUTE + `/contactbook/get-contact-book-group`
            console.log(action, "service calling!!!!!")
            const response = await requestToken.get(action);
            console.log(response, "response in service file get-contact-book-group!!!!!")
            return response;
        } catch (error) {
            console.error('Error in getContactgroups:', error);
            throw error;
        }
    }


    async sendPlanTextContactMessage(data) {
        try {
            let action = API_ROUTE + '/contactbook/send-contact-book-group-message'
            const response = await requestToken.post(action, data);
            return response;
        } catch (error) {

            console.error('Error in sendPlanTextMessage:', error);
            throw error;
        }
    }

    async getMobilenumberListbyidcontactgroup(idcontactGroups){
        try{
            let action = API_ROUTE + `/contactbook/get-mobile-numberlist/${idcontactGroups}`
            console.log(idcontactGroups,"heloooooooooooooo")
            const response =  await requestToken.get(action);
            console.log(response,"hiiiiiiiiiiiiiiiiii")
            return response;
        }catch(error){
            console.error(' error in getMobilenumberList:',error);
            throw error;
        }
        }

        async deleteMobilenumberbycontactlist(idContact_list){
            try{
                let action = API_ROUTE + `/contactbook/delete-mobile-number/${idContact_list}`
                console.log(idContact_list,"byyyyyyyyyyyy")
                const response = await requestToken.get(action);
                console.log(response,"hu jav chuuuuuuuuuuuu")
                return response;
            }catch(error){
                console.error('error in deletemobilenumber:',error);
                throw error;
            }
        
        }
    }

  
export default ContactList;
