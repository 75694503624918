import React, { Fragment, useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from 'react-redux';
import TemplateService from "../../../services/Template/Template";
import toast from "react-hot-toast";

const MessageWithButton = (props) => {
    const currentUserData = useSelector(state => state.profile.profile);
    const searchSchema = Yup.object().shape({
        template_name: Yup.string().required("Cannot be empty"),
        template_message: Yup.string().required("Cannot be empty"),
        template_footertext: Yup.string().required("Cannot be empty"),
        template_buttontext: Yup.array().of(
            Yup.object().shape({
                buttontext: Yup.string().required("Cannot be empty"),              
            })
          ),
    });
    const [buttonCount, setButtonCount] = useState(1);
    const [initialValue, setInitialValue] = useState({
        idtemplate_data:'',
        template_name: '',
        template_message: '',
        template_footertext: '',
        template_buttontext: [
            {
                buttontext_Id: 1,
                buttontext: '',
            }
        ],
    });
    const [count, setCount] = useState(1); 
    const increaseCount = (values, setFieldValue) => {
        setCount(count + 1);
        const newButtontext = {
            buttontext_id: count + 1,
            buttontext: ""
        };
        setFieldValue("template_buttontext", [...values.template_buttontext, newButtontext]);
    };
    useEffect(() => {
        if (props.isedit) {
            if (props.editTemplateData) {
                console.log(props.editTemplateData, "editTemplateData!!!!!!!!")
                setInitialValue(()=>({
                    idtemplate_data:props.editTemplateData.idtemplate_data,
                    template_name: props.editTemplateData.template_name,
                    template_message: props.editTemplateData.template_message,
                    template_footertext: props.editTemplateData.template_footertext,
                    template_buttontext: JSON.parse(props.editTemplateData.template_buttontext),                 
                }))
               
            }
        }
    }, [props.isedit])
    const handleDeleteButtonClick = (values, index) => {
        const updatedListButtontext = [...values.template_buttontext];
        updatedListButtontext.splice(index, 1);
    
        setInitialValue((prevState) => ({
          ...prevState,
          template_buttontext: updatedListButtontext,
        }));
      };
    const renderButtons = () => {
        const buttons = [];
        for (let i = 1; i <= buttonCount; i++) {
            buttons.push(
                <div className="form-group" key={i}>
                    <label>Button Text {i}</label>
                    <div className="input-group">
                        <input type="text" className="form-control" name={`button_text_${i}`} value="" required maxLength="50" />
                        {i > 1 && <div className="input-group-append">
                            <button type="button" className="btn btn-sm btn-danger" onClick={handleDeleteButtonClick}><i className="fas fa-trash-alt"></i></button>
                        </div>}
                    </div>
                </div>
            );
        }
        return buttons;
    };

    return (
        <div className="tab-pane fade show active" id="mode_1" role="tabpanel" aria-labelledby="home-tab4">
            {/* <form method="POST" className="ajaxform_reset_form"> */}
            <Formik
                validationSchema={searchSchema}
                initialValues={initialValue}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    console.log(values,"values in message with button!!!!")
                    const data = {
                        user_id: currentUserData?.currentUserData?.result?.user_id,
                        idtemplate_data: values.idtemplate_data,
                        template_name: values.template_name,
                        template_message: values.template_message,
                        template_footertext: values.template_footertext,
                        template_buttontext:values.template_buttontext
                    }

                    const saveTemplate = new TemplateService();
                    if(props.isedit){
                        saveTemplate
                        .updateMessageWithButtonTemplateData(data)
                        .then((response) => {
                            // console.log(response,"response in add participant in group!!!!")
                            if (response.isSuccess === true) {
                                toast.success(response?.message);
                            } else {
                                toast.error(response?.message);
                            }
                            resetForm();
                            setSubmitting(false)
                            props.setEdit(false)
                            props.getTemplates();
                        })
                        .catch((error) => {
                            console.error("Error fetching user profile:", error);
                        });
                    }else{
                        saveTemplate
                        .saveMessageWithButtonTemplateData(data)
                        .then((response) => {
                            // console.log(response,"response in add participant in group!!!!")
                            if (response.isSuccess === true) {
                                toast.success(response?.message);
                            } else {
                                toast.error(response?.message);
                            }
                            resetForm();
                            setSubmitting(false)
                        })
                        .catch((error) => {
                            console.error("Error fetching user profile:", error);
                        });
                    }
                    
                }}
            >
                {({ values, setFieldValue, isSubmitting, errors }) =>
                    <Form>
                        <Fragment>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>Template Name</label>
                                        <input type="text" name="template_name" className="form-control mb-0" value={values.template_name} onChange={(e) => setFieldValue('template_name', e.target.value)} />
                                        <ErrorMessage name="template_name">
                                            {(msg) => (
                                                <div className="error" style={{ color: "red" }}>
                                                    {msg}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-row mb-1">
                                        <label className="col-6">Message:</label>
                                        <div className="col-6">
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control h-200" name="template_message" maxLength="1000" value={values.template_message} onChange={(e) => setFieldValue('template_message', e.target.value)}></textarea>
                                        <ErrorMessage name="template_message">
                                            {(msg) => (
                                                <div className="error" style={{ color: "red" }}>
                                                    {msg}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>Footer Text</label>
                                        <input type="text" name="template_footertext" className="form-control mb-0" maxLength="50" value={values.template_footertext} onChange={(e) => setFieldValue('template_footertext', e.target.value)} />
                                        <ErrorMessage name="template_footertext">
                                            {(msg) => (
                                                <div className="error" style={{ color: "red" }}>
                                                    {msg}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="button" id="add-more-action" className="btn btn-sm btn-primary btn-neutral float-right mb-1" onClick={() => { increaseCount(values, setFieldValue) }}><i className="fa fa-plus"></i> Add More</button>
                                </div>
                                {values.template_buttontext && values.template_buttontext.map((button, index) => (
                                    <div className="form-group" key={index}>
                                        <label>Button Text</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" name={`template_buttontext[${index}].buttontext`} value={values.template_buttontext[index].buttontext} onChange={(e) => setFieldValue(`template_buttontext[${index}].buttontext`, e.target.value)} maxLength="50" />
                                            <div className="input-group-append">
                                                <button type="button" className="btn btn-sm btn-danger" onClick={()=>{handleDeleteButtonClick(values, index)}}><i className="fas fa-trash-alt"></i></button>
                                            </div>
                                           
                                        </div>
                                        <ErrorMessage name={`template_buttontext[${index}].buttontext`}>
                                            {(msg) => (
                                                <div className="error" style={{ color: "red" }}>
                                                    {msg}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                ))}

                                {/* {renderButtons()} */}
                                <div className="col-sm-12">
                                    <button type="submit" className="btn btn-outline-primary submit-button">{props.isedit ? 'Update Template':'Save Template'}</button>                                  
                                </div>
                            </div>
                        </Fragment>
                    </Form>
                }


            </Formik>
        </div>
    );
}

export default MessageWithButton;
