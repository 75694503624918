import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DoNotDisturbOutlinedIcon from "@mui/icons-material/DoNotDisturbOutlined";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import axios from "axios";
import UserService from "../../services/users/UserService";
import toast from "react-hot-toast";
const ManageUserRoles = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userId, setUserId] = useState(null);
  const [roles, setRoles] = useState([]);
  const [userRole, setUserRole] = useState([]);
  const [selectedValue, setSelectedValue] = useState("None");
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    console.log(location.state, "ofdlele;;");
    if (location.state && location.state.userId) {
      setUserId(location.state.userId);
    }
  }, [location.state]);

  const getUserRoleData = (userId) => {
    const getUserRoleData = new UserService();
    getUserRoleData
      .getUserRoleData(userId)
      .then((response) => {
        console.log(response, "get user role");
        if (response.isSuccess === "success") {
          setUserRole(response.result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const assignSelectedRoles = (assignedRole) => {
    console.log("Assign Role:", assignedRole);
    const payload = {
      userId: userId,
      roleId: assignedRole,
    };
    const assignUserRole = new UserService();
    assignUserRole
      .assignUserRole(payload)
      .then((response) => {
        console.log(response, "assigned Roles result");
        if (response.isSuccess === true) {
          toast.success(response.message);
          getUserRoleData(userId);
        } else {
          toast.success(response.message);
          getUserRoleData(userId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async function getRoles() {
    try {
      const url = `${process.env.REACT_APP_NODE_URL}/api/roles/get-roles`;
      const config = {
        headers: {
          token: localStorage.getItem("isToken"),
        },
      };
      const response = await axios.get(url, config);
      if (response.data) {
        console.log(response.data.result, "roles result");
        setRoles(response.data.result);
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  }
   const onChangeRole = (assignRoles) => {
    setSelectedValue(assignRoles);
    assignSelectedRoles(assignRoles)
    // Perform any other actions based on the selected value
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!isOpen) {
        setSelectedValue("None");
      }
    }, 200);

    return () => clearTimeout(timeoutId);
  }, [isOpen]);
  const handleRemoveRole = (roleId) => {
    console.log(roleId, "roleissss");
    const payload = {
      userId: userId,
      roleId: roleId,
    };
    const removeUserRole = new UserService();
    removeUserRole
      .removeUserRole(payload)
      .then((response) => {
        if (response.isSuccess === true) {
          console.log(response, "remove result");
          toast.success(response.message);
          getUserRoleData(userId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (userId) {
      getUserRoleData(userId);
    }
  }, [userId]);
  useEffect(() => {
    getRoles();
  }, []);

  const handleRedirect = () => {
    navigate(-1);
  };

  return (
    <div className="container-fluid shadow p-3 mb-3 bg-white rounded">
      <div className="col-md-12 d-flex justify-content-end">
        <div className="d-flex align-items-center">
          {/* <button
                type="button"
                className="btn btn-outline-secondary ml-2"
                // onClick={openSubscription}
              >
                Add Subscription
              </button> */}
          <button
            type="button"
            className="btn btn-outline-secondary ml-2"
            onClick={handleRedirect}
          >
            Back
          </button>
        </div>
      </div>
      <main className="mt-4">
        <h5 className="m-0 text-muted">Access Level</h5>
        <div className="row mt-3 mb-3 m-0">
          <section className="d-flex mt-3 flex-column col-12">
            <div className="row">
              <div className="col-lg-5 border border-primary p-4 mx-2">
                <div className="d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    className="bi bi-person-fill-check text-success"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    <path d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z" />
                  </svg>

                  <h6 className="fw-bold ms-3 mb-0">Roles</h6>
                </div>
                <div className="mt-3">
                  <select
                    className="form-select dropup"
                    id="access"
                    name="access"
                    style={{ height: "40px", width: "100%" }}
                    defaultValue="None"
                    value={selectedValue}
                    onChange={(e) => onChangeRole(e.target.value)}
                    onBlur={(e) => {
                      setIsOpen(false);
                      setSelectedValue(e.target.value);
                    }}
                    onFocus={() => setIsOpen(true)}
                  >
                    <option value="None">None</option>
                    {roles.map((role, index) => (
                      <>
                        <option key={index} value={role.id}>
                          {role.role}
                        </option>
                      </>
                    ))}
                  </select>
                </div>
                <p className="mt-3 mb-0">
                  Admins have all rights and are able to do all things.
                </p>
              </div>
              <div className="col-lg-5 border border-danger p-4 mx-2">
                <div className="d-flex align-items-center">
                  <AccessibilityIcon
                    style={{ fontSize: "25px", color: "red" }}
                  />
                  <h6 className="fw-bold ms-3 mb-0">Access</h6>
                </div>
                <div className="mt-3">
                  {userRole.map((role, index) => (
                    <div
                      key={index}
                      className="d-flex align-items-center mb-2 role-container"
                    >
                      <input
                        disabled
                        className="form-control flex-grow-1 me-2 role-input"
                        value={role.role}
                        name={role.role}
                      />
                      <button
                        type="button"
                        className="btn btn-outline-danger d-flex align-items-center justify-content-center btn-sm role-remove-btn"
                        onClick={() => handleRemoveRole(role.id)}
                      >
                        <CloseOutlinedIcon />
                      </button>
                    </div>
                  ))}
                </div>

                <p className="mt-3 mb-0">
                  They will be able to login using the access level you choose.
                </p>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default ManageUserRoles;
