

import React, { Fragment, useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { FaAddressBook, FaSignal } from 'react-icons/fa';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import "../../Styles/contactpage.css";
import ContactListService from "../../services/contactlist/ContactList";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Alert,
  Label, Table as ReactTable, Badge
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import toast from "react-hot-toast";
import AlertDeleteModal from "../AlertDelete/AlertDeleteModal";
import AddContact from './AddContact';
import ImportContacts from './ImportContacts';
import BlackList from '../../services/blacklist/Blacklist';
const ContactsBook = () => {
  const [centeredModal, setCenteredModal] = useState(false);
  const currentUserData = useSelector(state => state.profile.profile);
  const [GroupListData, setGroupListData] = useState([]);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [type, setType] = useState(null);
  const [id, setId] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const handleEdit = (contact) => {
    console.log(contact, "contact!!!! in edit button!!!!!")
    setCenteredModal(true)
    setInitialValues({
      Username: contact.contact_name,
      whatsappno: contact.whatsappno,
      idBlocked_contact_list: contact.idBlocked_contact_list
    })
  }
  const closeModal = () => {
    setCenteredModal(false)
  }
  const searchSchema = Yup.object().shape({
    Username: Yup.string().required("Cannot be empty"),
    whatsappno: Yup.number().required("Cannot be empty"),
    //   group: Yup.mixed().required("Cannot be empty"),
  });
  const [initialValues, setInitialValues] = useState({
    Username: '',
    whatsappno: '',
    group: '',
    idBlocked_contact_list: ''
  })

  const checkTabGrant = () => {
    const roleArray = localStorage.getItem("roleArray");
    if (!roleArray) {
      navigate('/homeScreen');
    } else {
      const checkList = roleArray.split(",");
      if (checkList.find((items) => items === "contactbook")) {
        console.log("User has access to contactbook!");
      } else {
        navigate('/home');
      }
    }
  };

  const getAllContacts = () => {
    const contactService = new BlackList();
    contactService
      .getBlackContactListDatabyUser_id()
      .then((response) => {
        console.log(response, "response in get contact  list by userid!!!!!!!");
        if (response.isSuccess === "success") {
          // if (response.response.length > 0) {
            setAllContactListData(response.response);
          // }
        }
      })
      .catch((error) => {
        console.error("Error fetching chat group:", error);
      });
  }
  const submitDelete = async (type, id) => {
    const deleteParticipant = new BlackList();
    deleteParticipant.deleteblackcontactbyuserid(id)
      .then((response) => {
        if (response.isSuccess === true) {        
          toast.success(response?.message);
          setDisplayConfirmationModal(false);
          closeModal(); 
          getAllContacts();                 
        } else {
          toast.error(response?.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching user profile:", error);
      });
  }


  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };
  const deleteActionCall = (contact) => {
    setType("contact_delete");
    setId(contact.idBlocked_contact_list);
    setDeleteMessage(
      `Are You Sure You Want To Delete This Contact '${contact.contact_name}'?`
    );
    setDisplayConfirmationModal(true);
  };
  const ContactTable = ({ allContactListData, filterText }) => {
    const filteredContacts = allContactListData.length > 0 && allContactListData.filter(
      (contact) => contact.contact_name.indexOf(filterText) !== -1
    );

    return (
      <>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>
                <i className="fa fa-fw fa-user"></i>Name
              </th>
              <th>
                <i className="fa fa-fw fa-phone"></i>Phone
              </th>
              <th>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {allContactListData.length > 0 && allContactListData.map((contact) => (
              <tr key={contact.key}>
                <td>{contact.contact_name}</td>
                <td>{contact.whatsappno}</td>
                <td>
                  <div className="d-flex">
                    <DeleteIcon onClick={() => deleteActionCall(contact)} />
                    <AlertDeleteModal
                      showModal={displayConfirmationModal}
                      confirmModal={submitDelete}
                      hideModal={hideConfirmationModal}
                      type={type}
                      id={id}
                      message={deleteMessage}
                    />


                    <EditIcon onClick={() => handleEdit(contact)} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
       
      </>
    );
  };

  const NewContactRow = ({ addContact }) => {
    const handleSubmit = (event) => {
      event.preventDefault();
      const target = event.target;
      const name = target.name.value;
      const phone = target.phone.value;
      const email = target.email.value;

      const contact = {
        name: name,
        phone: phone,
        email: email,
        key: new Date().getTime(),
      };
      addContact(contact);
      target.reset();
    };

    return (
      <>
        <form className="form-inline" onSubmit={handleSubmit}>
          <div className="form-group row">
            <div className="col-md-3">
              <input
                type="text"
                name="name"
                className="form-control"
                id="nameInput"
                placeholder="Name"
              />
            </div>
            <div className="col-md-3">
              <input
                type="text"
                name="phone"
                className="form-control"
                id="phoneInput"
                placeholder="Phone"
              />
            </div>
            <div className="col-md-3">
              <input
                type="email"
                name="email"
                className="form-control"
                id="emailInput"
                placeholder="Email"
              />
            </div>
            <div className="col-md-3">
              <button type="submit" className="btn btn-primary">
                <i className="fa fa-fw fa-plus"></i>Add
              </button>
            </div>
          </div>
        </form>

      </>
    );
  };

  const FilterableContactTable = (props) => {
    const [filterText, setFilterText] = useState('');
    const [contacts, setContacts] = useState([
    ]);

    const handleFilterTextInput = (value) => {
      setFilterText(value);
    };
    const addContact = (contact) => {
      setContacts([...contacts, contact]);
    };

    return (
      <div className='mt-5'>
        {/* <h3>Add Contact</h3> */}
        {/* <form>
        <input
          className="form-control"
          type="text"
          placeholder="Search..."
          value={filterText}
          onChange={(e) => handleFilterTextInput(e.target.value)}
        />
      </form>
      <br/>
      <NewContactRow addContact={addContact} /> */}
        <ContactTable allContactListData={allContactListData} filterText={filterText} />
      </div>
    );
  };

  // const ContactsBook = () => {
  const navigate = useNavigate()
  const [allContactListData, setAllContactListData] = useState([]);


  useEffect(() => {
    getAllContacts()
  }, [currentUserData])



  return (
    <>
      <div className="container-fluid mt-5">
        <div className="d-flex  flex-wrap justify-content-end">
          <AddContact getAllContacts={getAllContacts} />
          <ImportContacts GroupListData={GroupListData} getAllContacts={getAllContacts} />
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="row d-flex justify-content-between flex-wrap">
              <div className="col">
                <div className="card card-stats">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <span className="h2 font-weight-bold mb-0 total-transfers" id="total-device">
                          {allContactListData.length > 0 ? allContactListData.length : '0'}
                        </span>
                      </div>
                      <div className="col-auto">
                        <div className="blue">
                          <div style={{ backgroundColor: 'blue', padding: '10px', borderRadius: '8px', display: 'inline-block', color: 'white', fontSize: '20px' }}>
                            <FaAddressBook />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="mt-3 mb-0 text-sm"></p>
                    <h5 className="card-title text-muted mb-0">Total Contacts</h5>
                    <p></p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card card-stats">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <span className="h2 font-weight-bold mb-0 total-transfers" id="total-active">
                          {allContactListData.length > 0 ? allContactListData.length : '0'}
                        </span>
                      </div>
                      <div className="col-auto">
                        <div style={{ backgroundColor: 'blue', padding: '10px', borderRadius: '8px', display: 'inline-block', color: 'white', fontSize: '20px' }}>
                          <FaSignal />
                        </div>
                      </div>
                    </div>
                    <p className="mt-3 mb-0 text-sm"></p>
                    <h5 className="card-title text-muted mb-0">Contacts Statistics</h5>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
            {/* <ContactTable allContactListData={allContactListData} /> */}
            <table className="table table-hover">
          <thead>
            <tr>
              <th>
                <i className="fa fa-fw fa-user"></i>Name
              </th>
              <th>
                <i className="fa fa-fw fa-phone"></i>Phone
              </th>
              <th>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {allContactListData.length > 0 && allContactListData.map((contact) => (
              <tr key={contact.key}>
                <td>{contact.contact_name}</td>
                <td>{contact.whatsappno}</td>
                <td>
                  <div className="d-flex">
                    <DeleteIcon onClick={() => deleteActionCall(contact)} />
                    <AlertDeleteModal
                      showModal={displayConfirmationModal}
                      confirmModal={submitDelete}
                      hideModal={hideConfirmationModal}
                      type={type}
                      id={id}
                      message={deleteMessage}
                    />


                    <EditIcon onClick={() => handleEdit(contact)} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
          </div>
        </div>
      </div>
      <Modal
          isOpen={centeredModal}
          toggle={closeModal}
          className="modal-dialog-centered modal-lg"
          backdrop={true}
        >
          <ModalHeader toggle={closeModal}>
            <h3>Edit Contact</h3>
          </ModalHeader>
          <Formik
            validationSchema={searchSchema}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              console.log(values, "values in Edit contact form!!!!")
              let data = {
                contact_name: values.Username,
                whatsappno: values.whatsappno,
                user_id: currentUserData?.currentUserData?.result?.user_id,
                idBlocked_contact_list: values.idBlocked_contact_list
              };
              console.log(data, "data in Edit contact!!!!!")
              const addParticipant = new BlackList();
              addParticipant
                .updateblackcontactbyuserid(data)
                .then((response) => {
                  // console.log(response,"response in add participant in group!!!!")
                  if (response.isSuccess === true) {
                    toast.success(response?.message);
                    closeModal();
                    getAllContacts();
                  } else {
                    toast.error(response?.message);
                  }
                  resetForm();
                  setSubmitting(false)
                })
                .catch((error) => {
                  console.error("Error fetching user profile:", error);
                });
            }
            }

          >
            {({ values, setFieldValue, isSubmitting }) =>
              <Form>
                <Fragment>
                  <ModalBody>
                    <Row className="align-items-center ">
                      <Col>
                        <Field name="Username">
                          {({ field, form }) => (
                            <div className="mb-3">
                              <label for="exampleInputPassword1" className="form-label">Contact Name</label>
                              <input type="text" className="form-control mb-0" id="Username" name="Username" value={values.Username} onChange={(e) => setFieldValue('Username', e.target.value)} />
                              <ErrorMessage name="Username">
                                {(msg) => (
                                  <div className="error" style={{ color: "red" }}>
                                    {msg}
                                  </div>
                                )}
                              </ErrorMessage>
                            </div>
                          )}
                        </Field>

                      </Col>
                    </Row>
                    <Row className="align-items-center ">
                      <Col>
                        <Field name="whatsappno">
                          {({ field, form }) => (
                            <div className="mb-3">
                              <label for="exampleInputPassword1" className="form-label">Whatsapp Number</label>
                              <input type="number" className="form-control mb-0" placeholder='Enter Phone number with country code' id="whatsappno" name="whatsappno" value={values.whatsappno} onChange={(e) => setFieldValue('whatsappno', e.target.value)} />
                              <ErrorMessage name="whatsappno">
                                {(msg) => (
                                  <div className="error" style={{ color: "red" }}>
                                    {msg}
                                  </div>
                                )}
                              </ErrorMessage>
                            </div>
                          )}
                        </Field>

                      </Col>
                    </Row>

                    <hr />
                    <Row className="align-items-center ">
                      <Col md="2" sm="12">
                        <Button color="primary" type="submit" disabled={isSubmitting}>
                          {isSubmitting ? 'Updating...' : 'Update'}
                        </Button>
                      </Col>
                    </Row>
                    <hr />
                  </ModalBody>
                </Fragment>
              </Form>
            }
          </Formik>

        </Modal>
    </>
  );
};

export default ContactsBook;

