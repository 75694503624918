import React from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from 'react-redux';
import TemplateService from "../../../services/Template/Template";
import toast from "react-hot-toast";
import { Fragment, useEffect, useState } from "react";
const SendLocation = (props) => {
  const currentUserData = useSelector(state => state.profile.profile);
  const searchSchema = Yup.object().shape({
    template_name: Yup.string().required("Cannot be empty"),
    template_longitude: Yup.number().required("Cannot be empty"),
    template_latitude: Yup.number().required("Cannot be empty")
  });
  const [initialvalues, setInitialvalues] = useState({
    idtemplate_data: '',
    template_name: '',
    template_latitude: '',
    template_longitude: ''
  });
  useEffect(() => {
    if (props.isedit) {
      if (props.editTemplateData) {
        console.log(props.editTemplateData, "editTemplateData!!!!!!!!")
        setInitialvalues(() => ({
          idtemplate_data: props.editTemplateData.idtemplate_data,
          template_name: props.editTemplateData.template_name,
          template_latitude: props.editTemplateData.template_latitude,
          template_longitude: props.editTemplateData.template_longitude,
        }))
      }
    }
  }, [props.isedit])
  return (
    <div>
      <div className="tabe-pane" id="mode_7" role="tabpanel" aria-labelledby="profile-tab4">
        <Formik
          validationSchema={searchSchema}
          initialValues={initialvalues}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            console.log(values, "values in message with button!!!!")
            const data = {
              user_id: currentUserData?.currentUserData?.result?.user_id,
              idtemplate_data: values.idtemplate_data,
              template_name: values.template_name,
              template_latitude: values.template_latitude,
              template_longitude: values.template_longitude,
            }

            const saveTemplate = new TemplateService();
            if(props.isedit){
              saveTemplate
              .updatelocationData(data)
              .then((response) => {
                // console.log(response,"response in add participant in group!!!!")
                if (response.isSuccess === true) {
                  toast.success(response?.message);
                } else {
                  toast.error(response?.message);
                }
                resetForm();
                setSubmitting(false)
                props.setEdit(false);
                props.getTemplates();
              })
              .catch((error) => {
                console.error("Error fetching user profile:", error);
              });
            }else{
              saveTemplate
              .savelocationData(data)
              .then((response) => {
                // console.log(response,"response in add participant in group!!!!")
                if (response.isSuccess === true) {
                  toast.success(response?.message);
                } else {
                  toast.error(response?.message);
                }
                resetForm();
                setSubmitting(false)
              })
              .catch((error) => {
                console.error("Error fetching user profile:", error);
              });
            }
           
          }}
        >
          {({ values, setFieldValue, isSubmitting, errors }) =>
            <Form>
              <Fragment>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Template Name:</label>
                      <input type="text" name="template_name" className="form-control mb-0" value={values.template_name} onChange={(e) => setFieldValue('template_name', e.target.value)} />
                      <ErrorMessage name="template_name">
                        {(msg) => (
                          <div className="error" style={{ color: "red" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Latitude</label>
                      {/* <input type="number" step="any" name="degreesLatitude" required="" className="form-control" placeholder="Example: 24.121231" /> */}
                      <input type="number" name="template_latitude" className="form-control mb-0" placeholder="Example: 24.121231" value={values.template_latitude} onChange={(e) => setFieldValue('template_latitude', e.target.value)} />
                      <ErrorMessage name="template_latitude">
                        {(msg) => (
                          <div className="error" style={{ color: "red" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Longitude</label>
                      {/* <input type="number" step="any" name="degreesLongitude" required="" className="form-control" placeholder="Example: 55.1121221" /> */}
                      <input type="number" name="template_longitude" className="form-control mb-0" placeholder="Example: 55.1121221" value={values.template_longitude} onChange={(e) => setFieldValue('template_longitude', e.target.value)} />
                      <ErrorMessage name="template_longitude">
                        {(msg) => (
                          <div className="error" style={{ color: "red" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <button type="submit" className="btn btn-outline-primary submit-button float-left">{props.isedit ? 'Update Template' : 'Save Template'}</button>
                  </div>
                </div>
              </Fragment>
            </Form>
          }
        </Formik>
      </div>
    </div>
  )
}

export default SendLocation
