//  checkDeviceIsConnectionSlice


import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    ActiveDeviceCount: 0,
    message: '',
}

const checkDeviceIsConnectionSlice = createSlice({
    name: 'checkDeviceIsConnection',
    initialState,
    reducers: {
        setDeviceConnectionMessage: (state, action) => {
            state.message = action.payload
        },
        setActiveDeviceCount: (state, action) => {
            state.ActiveDeviceCount = action.payload
        }
    }
})

export const { setDeviceConnectionMessage, setActiveDeviceCount } = checkDeviceIsConnectionSlice.actions;
export default checkDeviceIsConnectionSlice.reducer;

