import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
  startSession: {
    isSuccess: false,
    isFetching: false,
    isError: false,
    start: "",
  },
};

export const setStartSession = createAsyncThunk(
  "startSession/setStartSession",
  async (sessionId) => {
    const config = {
      headers: {
        token: localStorage.getItem("isToken"),
      },
    };
    const url = `${process.env.REACT_APP_NODE_URL}/api/devices/start-session/${sessionId}`;
    const response = await axios.get(url, config);
    console.log(response.data, 'startResiodm')
    return response.data;
  }
);

const startSessionSlice = createSlice({
  name: "startSession",
  initialState,
  reducers: {
    clearStartSessionState: (state) => {
      state.startSession.isError = false;
      state.startSession.isFetching = false;
      state.startSession.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setStartSession.pending, (state) => {
      state.startSession.isFetching = true;
    });
    builder.addCase(setStartSession.fulfilled, (state, action) => {
      state.startSession.isFetching = false;
      state.startSession.isError = false;
      state.startSession.isSuccess = true;
      state.startSession.start = action.payload;
    });
    builder.addCase(setStartSession.rejected, (state) => {
      state.startSession.isFetching = false;
      state.startSession.isError = true;
    });
  },
});

export const { clearStartSessionState } = startSessionSlice.actions;
export default startSessionSlice.reducer;
