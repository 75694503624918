import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "../../../Styles/Dashboard.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfileData } from "../../../redux/slices/userProfileDataSlice";
import { Spinner } from "react-bootstrap";
import {
  setDeviceConnectionMessage,
  setActiveDeviceCount,
} from "../../../redux/slices/checkDeviceIsConnectionSlice";
import DeviceService from "../../../services/devices/DeviceService";
import LogoutIcon from '@mui/icons-material/Logout';

const Header = () => {
  const [userName, setUserName] = useState();
  const [profileimg, setProfileImage] = useState();
  const dispatch = useDispatch();
  const profileDataState = useSelector((state) => state.profile.profile);
  const [show, setShow] = useState(false);
  const profileRef = useRef(null);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  var myInterval;
  const checkDeviceDataState = useSelector(
    (state) => state.checkDeviceIsConnectionState.message
  );

  const handleOutsideClick = (event) => {
    // Check if the clicked element is outside the popup and the profile div
    if (
      show &&
      profileRef.current &&
      !profileRef.current.contains(event.target)
    ) {
      handleClose();
    }
  };

  const SentScheduleMessageBySessionId = async (session_id) => {
    const deviceService = new DeviceService();
    deviceService
      .sentPendingScheduleMessage(session_id)
      .then((response) => {
        if (response.isSuccess) {
          if (response.sendMessageCount != 0) {
            dispatch(
              setDeviceConnectionMessage("Send Schedule Massage Successfully")
            );
          }
          //setScannerConnectedMessage(response.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching user profile:", error);
      });
  };

  const checkDeviceIsConnected = async () => {
    const deviceService = new DeviceService();
    deviceService
      .checkDeviceIsConnected()
      .then((response) => {
        if (response.isSuccess) {
          if (response.result && response.result.length === 0) {
            clearInterval(myInterval);
          } else {
            const scheduleList = response.result;
            dispatch(setActiveDeviceCount(response.activeDeviceTotal));
            scheduleList.forEach(async (element) => {
              if (element.scanner_status === "Active") {
                // console.log("elementscheduleList>:", element);
                SentScheduleMessageBySessionId(element.session_id);
              }
            });
          }
        }
      })
      .catch((error) => {
        clearInterval(myInterval);
        console.error("Error checkDeviceIsConnected:", error);
      });
  };

  useEffect(() => {
    dispatch(getProfileData());
    clearInterval(myInterval);
    myInterval = setInterval(checkDeviceIsConnected, 60000);
  }, []);

  useEffect(() => {
    if (profileDataState && profileDataState.currentUserData.isSuccess) {
      if (profileDataState.currentUserData.result) {
        setUserName(profileDataState.currentUserData.result);
        if (profileDataState.currentUserData.result.image) {
          const binaryString = atob(
            profileDataState.currentUserData.result.image
          );

          const byteArray = new Uint8Array(binaryString.length);
          for (let i = 0; i < binaryString.length; i++) {
            byteArray[i] = binaryString.charCodeAt(i);
          }
          const blob = new Blob([byteArray], {
            type: "image/*", // Use the provided file type
          });
          const url = URL.createObjectURL(blob);
          setProfileImage(url);
        }
      }
    }
  }, [profileDataState]);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [show]);

  useEffect(() => {
    console.log(userName, "useRAh");
  }, [userName]);
  const onHandleProfile = () => {
    navigate("/profilesetting");
  };

  const handleLogout = () => {
    clearInterval(myInterval);
    navigate("/Login");
  };

  useEffect(() => {
    if (
      checkDeviceDataState &&
      checkDeviceDataState === "Create Device Successfully"
    ) {
      clearInterval(myInterval);
      myInterval = setInterval(checkDeviceIsConnected, 60000);
      dispatch(setDeviceConnectionMessage("Re-Start Interval"));
    } else if (
      checkDeviceDataState &&
      checkDeviceDataState === "After Refresh Start Interval"
    ) {
      clearInterval(myInterval);
      myInterval = setInterval(checkDeviceIsConnected, 60000);
      dispatch(setDeviceConnectionMessage("Re-Start Interval"));
    }
  }, [checkDeviceDataState]);

  return (
    <div className="" ref={profileRef}>
      <div className="d-flex justify-content-end">
        <div className="profile_icon d-flex" onClick={handleShow}>
          <div>
            {profileimg ? (
              <>
                <img
                  width={35}
                  height={35}
                  src={profileimg}
                  className="rounded-pill"
                />
              </>
            ) : (
              <>
                <div className="user-round">
                  {userName && userName.firstName && userName.lastname
                    ? `${userName.firstName[0]}${userName.lastname[0]}`
                    : "A"}
                </div>
              </>
            )}
          </div>
          <div className="userdata">
            {userName ? (
              <div>
                {userName.firstName} {userName.lastname}
              </div>
            ) : (
              <Spinner size={10} />
            )}
          </div>
        </div>
      </div>

      {show && (
        <div className="custom-popup-overlay">
          <div className="custom-popup">
            <label className="custom-popup-header">
              WELCOME!
            </label>
            
            <div className="">
              <div className="menu-item mb-2" onClick={onHandleProfile}>
                <h7 className="mx-2"> My Profile</h7>
              </div>
              <div className="menu-item  mb-2">
                <h7 className="mx-2">Subscription</h7>
              </div>
              <div className="menu-item  mb-2">
                <h7 className="mx-2">Auth Key</h7>
              </div>
              <div className="menu-item  mb-2">
                <h7 className="mx-2">Support</h7>
              </div>
            </div>
            <hr className="my-0"/>
            <div className="menu-item custom-popup-footer" onClick={handleLogout}>
              <h7 className="mx-2"> <LogoutIcon className="me-2" />Logout</h7>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
