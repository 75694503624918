import { requestToken, requestTokenImage } from '../../api/request_methods';

const API_ROUTE = `${process.env.REACT_APP_NODE_URL}/api/blackedlist`;
class BlackList {

    async addcontactblackbyuserid(data) {
        try {
            let action = API_ROUTE + '/add-contact-black-byuserid'
            const response = await requestToken.post(action, data);
            return response;
        } catch (error) {

            console.error('Error in save contact:', error);
            throw error;
        }
    }

    async importcontactblackbyuserid(data) {
        try {
            let action = API_ROUTE + '/import-contact-black-byuserid'
            const response = await requestTokenImage.post(action, data);
            return response;
        } catch (error) {

            console.error('Error in save contact:', error);
            throw error;
        }
    }

    async getBlackContactListDatabyUser_id() {
        try {
            let action = API_ROUTE + `/get-black-contact-list-byuserid`
            const response = await requestToken.get(action);
            console.log(response, "response in service file get-black-contact-list-byuserid!!!!!")
            return response;
        } catch (error) {
            console.error('Error in get-black-contact-list-byuserid:', error);
            throw error;
        }
    }

    async updateblackcontactbyuserid(data) {
        try {
            let action = API_ROUTE + '/update-black-contact-byuserid'
            const response = await requestToken.post(action, data);
            return response;
        } catch (error) {

            console.error('Error in save contact:', error);
            throw error;
        }
    }
    async deleteblackcontactbyuserid(idContact_list) {
        try {
            let action = API_ROUTE + `/delete-black-contact-byuserid/${idContact_list}`
            const response = await requestToken.post(action);
            return response;
        } catch (error) {

            console.error('Error in save contact:', error);
            throw error;
        }
    }
}
export default BlackList