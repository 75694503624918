import React from 'react';
import { Fragment, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from 'react-redux';
import TemplateService from "../../../services/Template/Template";
import toast from "react-hot-toast";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col,
    Alert,
    Label, Table as ReactTable, Badge
} from "reactstrap";
const PlainText = (props) => {
    const currentUserData = useSelector(state => state.profile.profile);
    const [initialvalues, setInitialvalues] = useState({
        idtemplate_data:'',
        template_name: '',
        template_message: ''
    });
    const searchSchema = Yup.object().shape({
        template_name: Yup.string().required("Cannot be empty"),
        template_message: Yup.string().required("Cannot be empty")
    });
    const [showModal,setShowModal]=useState(false)
    const showShortCode = ()=>{
        setShowModal(true) 
    }
   const closeModal=()=>{
        setShowModal(false) 
    }
    useEffect(() => {
        if (props.isedit) {
            if (props.editTemplateData) {
                console.log(props.editTemplateData, "editTemplateData!!!!!!!!")
                setInitialvalues(()=>({
                    idtemplate_data: props.editTemplateData.idtemplate_data,
                    template_name: props.editTemplateData.template_name,
                    template_message: props.editTemplateData.template_message
                }))
               
            }
        }
    }, [props.isedit])
    console.log(initialvalues,"initialvalues in plain text!!!!")
    return (
        <>
        <div>
            <div className="col-12 col-sm-12 col-md-12">
                <div className="tab-content no-padding" id="myTab2Content">
                    <div className="tab-pane fade show active" id="mode_1" role="tabpanel" aria-labelledby="home-tab4">
                        {/* <form method="POST" action="https://whats-api.rcsoft.in/user/template/store/plain-text" className="ajaxform_reset_form">
                            <input type="hidden" name="_token" value="3ZZMskxWtzcbkE7DOuIXJa4OyKeCuzSRZ3R3bxBR" />
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>Template Name</label>
                                        <input type="text" name="template_name" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-row mb-1">
                                        <label className="col-6">Message:</label>
                                        <div className="col-6">
                                            <button type="button" data-toggle="modal" data-target="#help-modal" className="btn btn-neutral btn-sm float-right"><i className="fas fas fa-code"></i>&nbsp;Shortcodes</button>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control h-200" name="message" required="" maxLength="1000"></textarea>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <button type="submit" className="btn btn-outline-primary submit-button">Save Template</button>
                                </div>
                            </div>
                        </form> */}
                        <Formik
                            validationSchema={searchSchema}
                            initialValues={initialvalues}
                            enableReinitialize={true}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                const data = {
                                    user_id: currentUserData?.currentUserData?.result?.user_id,
                                    idtemplate_data: values.idtemplate_data,
                                    template_name: values.template_name,
                                    template_message: values.template_message
                                }

                                const saveTemplate = new TemplateService();
                                if(props.isedit){                                  
                                    saveTemplate
                                    .updatePlaintextTemplateData(data)
                                    .then((response) => {
                                        // console.log(response,"response in add participant in group!!!!")
                                        if (response.isSuccess === true) {
                                            toast.success(response?.message);
                                        } else {
                                            toast.error(response?.message);
                                        }
                                        resetForm();
                                        setSubmitting(false)
                                        props.setEdit(false)
                                        props.getTemplates();
                                    })
                                    .catch((error) => {
                                        console.error("Error fetching user profile:", error);
                                    });
                                }else{
                                    saveTemplate
                                    .savePlaintextTemplateData(data)
                                    .then((response) => {
                                        // console.log(response,"response in add participant in group!!!!")
                                        if (response.isSuccess === true) {
                                            toast.success(response?.message);
                                        } else {
                                            toast.error(response?.message);
                                        }
                                        resetForm();
                                        setSubmitting(false)                                       
                                    })
                                    .catch((error) => {
                                        console.error("Error fetching user profile:", error);
                                    });
                                }
                               
                            }}
                        >
                            {({ values, setFieldValue, isSubmitting }) =>
                                <Form>
                                    <Fragment>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <label>Template Name</label>
                                                    <input type="text" name="template_name" className="form-control mb-0" value={values.template_name} onChange={(e) => setFieldValue('template_name', e.target.value)} />
                                                    <ErrorMessage name="template_name">
                                                        {(msg) => (
                                                            <div className="error" style={{ color: "red" }}>
                                                                {msg}
                                                            </div>
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="form-row mb-1">
                                                    <label className="col-6">Message:</label>
                                                    <div className="col-6">
                                                        <button type="button" data-toggle="modal" data-target="#help-modal" className="btn btn-neutral btn-sm float-right" onClick={showShortCode}><i className="fas fas fa-code"></i>&nbsp;Shortcodes</button>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <textarea className="form-control h-200" name="template_message" maxLength="1000" value={values.template_message} onChange={(e) => setFieldValue('template_message', e.target.value)}></textarea>
                                                    <ErrorMessage name="template_message">
                                                        {(msg) => (
                                                            <div className="error" style={{ color: "red" }}>
                                                                {msg}
                                                            </div>
                                                        )}
                                                    </ErrorMessage>
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                {/* <button type="submit" className="btn btn-outline-primary submit-button">Save Template</button> */}
                                                {props.isedit ?
                                                    <Button color="primary" type="submit" disabled={isSubmitting}>
                                                        {isSubmitting ? 'Updating...' : 'Update Template'}
                                                    </Button>
                                                    :
                                                    <Button color="primary" type="submit" disabled={isSubmitting}>
                                                        {isSubmitting ? 'Submitting...' : 'Save Template'}
                                                    </Button>
                                                }

                                            </div>
                                        </div>
                                    </Fragment>
                                </Form>
                            }


                        </Formik>
                    </div>
                </div>
            </div>
        </div>

        <Modal
                isOpen={showModal}
                toggle={closeModal}
                className="modal-dialog-centered modal-lg"
                backdrop={true}
            >
                {/* <ModalHeader toggle={closeModal}>
                    <h3>Short code</h3>
                </ModalHeader> */}
               
                                <ModalBody>
                                 
                                    <Row className="align-items-center ">
                                        <Col>
                                        <div className="d-flex d-flex justify-content-between"><h4>Shortcode</h4><span className='text-end' onClick={closeModal} style={{cursor:'pointer'}}>X</span></div>
                                           <Row>
                                            <p>&#123;name&#125; &#61; recipient can see his/her name</p>
                                            <p>&#123;phone_number&#125; &#61; recipient can see his/her phone_number</p>
                                            <p>&#123;my_name&#125; &#61; recipient can see your name</p>
                                            <p>&#123;my_email&#125; &#61; recipient can see your email</p>
                                            <p>&#123;my_contact_number&#125; &#61; recipient can see your contact number</p>
                                           </Row>
                                        
                                        </Col>
                                    </Row>


                                   
                                    <hr />
                                    <Row className="align-items-center ">
                                        <Col md="2" sm="12">                                  
                                            <Button color="primary" onClick={closeModal}>
                                               Close
                                            </Button>
                                        </Col>
                                    </Row>
                                </ModalBody>
                          
            </Modal>
        </>
    );
};

export default PlainText;
