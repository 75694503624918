import { requestToken, requestTokenImage } from "../../api/request_methods";

const API_ROUTE = `${process.env.REACT_APP_NODE_URL}/api/multi`;

class multiMessengerService {

  async sendMutliOptionMessage(payloads) {
    try {
      let action = API_ROUTE + "/set-messenger";
      const response = await requestTokenImage.post(action, payloads);
      return response;
    } catch (error) {
      console.error("Error in set-messenger:", error);
      throw error;
    }
  }  
  async sentMessageStackData(data) {
    try {
        let action = API_ROUTE + '/set-messenger'
        const response = await requestToken.post(action, data);
        return response;
    } catch (error) {

        console.error('Error in save contact:', error);
        throw error;
    }
}

async getAllCOntactListOfWgroup(data) {
    try {
      let action = API_ROUTE + "/getAllCOntactList-Wgroup";
      const response = await requestToken.post(action, data);
      return response;
    } catch (error) {
      console.error("Error in set-messenger:", error);
      throw error;
    }
  }
}

export default multiMessengerService;
