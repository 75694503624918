

import React, { Fragment, useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { FaAddressBook, FaSignal } from 'react-icons/fa';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import "../../Styles/contactpage.css";
import { saveAs } from 'file-saver';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col,
    Alert,
    Label, Table as ReactTable, Badge
} from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import toast from "react-hot-toast";
import AlertDeleteModal from "../AlertDelete/AlertDeleteModal";
import DeviceService from "../../services/devices/DeviceService";
import axios from "axios";
import multiMessengerService from "../../services/multiMessenger/multiMessengerServices";
const ExtractNumbers = () => {
    const [deviceList, setDeviceList] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [loading, setLoading] = useState(false);
    const [contactList, setContactList] = useState([]);
    const [chatList, setChatList] = useState([]);
    const [selectedChat, setSelectedChat] = useState([]);
    const [extractNumbersList, setExtractNumbersList] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const getContactList = async (selectedSessionId) => {
        try {
            const url = `${process.env.REACT_APP_NODE_URL}/api/devices/get-contact-list/${selectedSessionId}`;
            setLoading(true);
            const response = await axios.get(url);

            if (Array.isArray(response.data.contacts)) {
                const contactList = response.data.contacts.filter(
                    (contact) => !contact.isGroup
                );
                setContactList(contactList);
            } else {
                console.error("Invalid response data. Expected an array.");
            }
        } catch (error) {
            console.error("Error fetching contact list:", error.message);
        } finally {
            setLoading(false);
        }
    };
    const getChatList = async (selectedSessionId) => {
        try {
            const url = `${process.env.REACT_APP_NODE_URL}/api/devices/get-chat-list/${selectedSessionId}`;
            setLoading(true);
            const response = await axios.get(url);

            if (Array.isArray(response.data.chats)) {
                const chatListData = response.data.chats
                    .map((chat) => {
                        if (
                            chat.groupMetadata &&
                            chat.groupMetadata.subject &&
                            chat.isGroup === true
                        ) {
                            return { label: chat.groupMetadata.subject, value: chat.id };
                        }
                    })
                    .filter(Boolean);
                setChatList(chatListData);
            } else {
                console.error(
                    "Invalid response data. Expected an array for chat list."
                );
            }
        } catch (error) {
            console.error("Error retrieving chat list:", error.message);
        } finally {
            setLoading(false);
        }
    };
    const handleDeviceChange = (selectedOption) => {
        setSelectedDevice(selectedOption);
        if (selectedOption) {
            const selectedSessionId = selectedOption.session_id;
            getChatList(selectedSessionId);
            // getContactList(selectedSessionId);
        }
    };
    const getallActiveDevices = () => {
        const deviceService = new DeviceService();
        deviceService
            .getAllActiveDevices()
            .then((response) => {
                if (response.isSuccess === true) {
                    setDeviceList(response.result);
                }
            })
            .catch((error) => {
                console.error("Error fetching user profile:", error);
            });
    };
    const deviceOptions =
        deviceList &&
        deviceList.map((items) => ({
            value: items.id,
            label: items.device_name + " (+" + items.phone + ")",
            session_id: items.session_id,
            phone: items.phone,
        }));
    const handleChatChange = (selectedOption) => {
        setSelectedChat(selectedOption);
    };
    

   
    // const downloadCsvContacts = (contactsArray) => {
    //     let csvContent =  "";
    
    //     contactsArray.forEach(contact => {
    //         csvContent += `,${contact},\n`; // Add contact directly, assuming each contact is a string
    //     });
    
    //     const blob = new Blob([csvContent], { type: "text/csv" });
    //     const url = window.URL.createObjectURL(blob);
    //     const link = document.createElement("a");
    //     link.setAttribute("href", url);
    //     link.setAttribute("download", "contacts.csv");
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };
  const  downloadCsvContacts = (contactsArray) => {
        
        const csvData = [["contact_name", "whatsappno"]];
        contactsArray.forEach(number => {
          csvData.push(["", number]);
        });
    
        const csvContent = csvData.map(row => row.join(",")).join("\n");
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'contacts.csv');
      }
    const downloadCSV = () => {
        setSubmitting(true)
        console.log(selectedChat, "selectedChat!!!!!!!")
        console.log(selectedDevice, "selectedDevice!!!!!!!")
        if (selectedChat.length > 0) {
            let wGroupIds = selectedChat.map((group) => group.value._serialized);
            let session_id = selectedDevice.session_id;
            const data = {
                wGroupIds: wGroupIds,
                session_id: session_id
            }
            const multiMessenger = new multiMessengerService();
            multiMessenger
                .getAllCOntactListOfWgroup(data)
                .then((response) => {
                    if (response.isSuccess === true) {
                        if (response.response.length > 0) {                          
                            // const newArray = response.response.map(obj => {
                            //     const newObj = {};
                            //     for (const [key, value] of Object.entries(obj)) {
                            //         newObj[key === 'undefined' ? '' : key] = value;
                            //     }
                            //     return newObj;
                            // });
                             console.log(response.response, "response in getAllCOntactListOfWgroup!!!!!!")
                            // setExtractNumbersList(response.response);
                            toast.success(response.message);
                             downloadCsvContacts(response.response);
                        } else {
                            toast.error("No Contacts in Selected Group!!!")
                        }
                    } else {
                        toast.error(response.message)
                    }
                      setSubmitting(false)
                })
                .catch((error) => {
                    console.error("Error fetching user profile:", error);
                      setSubmitting(false)
                });

        }
       
    }
    useEffect(() => {
        getallActiveDevices();
    }, []);
    return (
        <>
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col">
                        <div className="card">
                            <div className="row m-3">
                                <div className="form-group col-sm-6">
                                    <label className="form-label">Select Device</label>
                                    <Select
                                        isMulti={false}
                                        options={deviceOptions}
                                        value={selectedDevice}
                                        onChange={handleDeviceChange}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <label htmlFor="group-name">Group Name</label>
                                    {loading?<span>&#40;	Loading... &#41;</span>:<span></span>}
                                    {/* <span>{loading ? '(' : ''}Loading ...{loading ? ')' : ''}</span> */}
                                    <div>
                                        <Select
                                            isMulti
                                            options={chatList}
                                            // options={
                                            //     loading
                                            //         ? [{ label: "Loading....", value: "loading" }]
                                            //         : chatList
                                            // }                                          
                                            onChange={handleChatChange}
                                            value={selectedChat}
                                            isDisabled={loading}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2 me-2">
                                <div className="d-flex justify-content-end">
                                    <button
                                        onClick={downloadCSV}
                                        className="btn btn-outline-primary"
                                        disabled={submitting}
                                    >
                                       {submitting? 'Extracting...':' Extract and Download'}
                                    </button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </>
    );
};

export default ExtractNumbers;

