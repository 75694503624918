// App.js
import React from "react";
import { lazy, Suspense } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import HomeScreen from "./components/HomeScreen";
import Registration from "./components/Registration";
import Login from "./components/Login";
import ErrorPage from "./pages/misc/ErrorPage";
import { ErrorBoundary } from "react-error-boundary";

// ** Router Import
import Router from "./routers/index";
import { useEffect } from "react";
// const PublicRoute = () => {
//    const isToken = localStorage.getItem('isToken');
//   console.log(isToken, 'Appsod')
//   return isToken && isToken.length > 0 ? element : <Navigate to="/" />;
// };

let isAdminExist = false;

const App = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const isToken = localStorage.getItem("isToken");
    const currentUrl = window.location.href;
    const urlFindMyDevicesKw = currentUrl.includes("register");
    const urllogin = currentUrl.includes("login");

    // console.log('urlFindMyDevicesKw >>', urlFindMyDevicesKw, urlemp_registrationlink);

    if (!isToken && !urlFindMyDevicesKw && !urllogin) {
      navigate("/homeScreen");
    }
  }, []);
  return (
    <Suspense fallback={null}>
      {/* {store.commonStore.spinner ? <PageLoader></PageLoader> : null} */}
      <ErrorBoundary
        FallbackComponent={ErrorPage}
        onError={(error) => {
          console.log(error);
        }}
      >
        <Router />
      </ErrorBoundary>
    </Suspense>
  );
};

export default App;

// import React from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import HomeScreen from './components/HomeScreen';
// import Registration from './components/Registration';
// import Login from './components/Login';

// const App = () => {
//   const isFirstTimeUser = localStorage.getItem('isFirstTimeUser');

//   return (

//       <Routes>
//         <Route
//           path="/"
//           element={
//             isFirstTimeUser ? (
//               <Navigate to="/register" replace />
//             ) : (

//               <Navigate to="/login" replace />
//             )
//           }
//         />
//         <Route path="/login" element={<Login />} />
//         <Route path="/register" element={<Registration />} />
//         <Route path="/home" element={<HomeScreen />} />
//       </Routes>

//   );
// };

// export default App;
