import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  sessionQrImage: {
    isSuccess: false,
    isFetching: false,
    isError: false,
    qrImage: "",
  },
};

export const getSessionQrImage = createAsyncThunk(
  "sessionQrImage/sessionQrImageSlice",
  async (sessionId) => {
    console.log(sessionId, "sessionidf");
    const config = {
      headers: {
        token: localStorage.getItem("isToken"),
      },
    };
    const imageUrl = `${process.env.REACT_APP_NODE_URL}/api/devices/get-qr-image/${sessionId}`;
    const response = await axios.get(imageUrl, config);
    if (response.status === 200) {
      console.log("QR image retrieved successfully:", response.data);
      return URL.createObjectURL(response.data);
    }
  }
);

const sessionQrImageSlice = createSlice({
  name: "sessionQrImage",
  initialState,
  reducers: {
    clearSessionQrImage: (state) => {
      state.sessionQrImage.isError = false;
      state.sessionQrImage.isFetching = false;
      state.sessionQrImage.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSessionQrImage.pending, (state) => {
      state.sessionQrImage.isFetching = true;
    });
    builder.addCase(getSessionQrImage.fulfilled, (state, action) => {
      state.sessionQrImage.isFetching = false;
      state.sessionQrImage.isError = false;
      state.sessionQrImage.isSuccess = true;
      state.sessionQrImage.qrImage = action.payload;
    });
    builder.addCase(getSessionQrImage.rejected, (state) => {
      state.sessionQrImage.isFetching = false;
      state.sessionQrImage.isError = true;
    });
  },
});

export const { clearSessionQrImage } = sessionQrImageSlice.actions;
export default sessionQrImageSlice.reducer;
