import { requestToken } from '../../api/request_methods';

const API_ROUTE =`${process.env.REACT_APP_NODE_URL}/api/devices`;

class DeviceService {
  async getAllDevices() {
    try {
        let action = API_ROUTE+'/get-devices'
      const response = await requestToken.get(action);
      return response;
    } catch (error) {
     
      console.error('Error in getUserProfile:', error);
      throw error;
    }
  }
  async getAllActiveDevices() {
    try {
        let action = API_ROUTE+'/get-active-devices'
      const response = await requestToken.get(action);
      return response;
    } catch (error) {
     
      console.error('Error in getUserProfile:', error);
      throw error;
    }
  }

  async getScannerStatus(sessionId) {
    try {
        let action = API_ROUTE+`/get-scanner-status/${sessionId}`
      const response = await requestToken.get(action);
      return response;
    } catch (error) {
     
      console.error('Error in getUserProfile:', error);
      throw error;
    }
  }
  async getAllowDevice(userId) {
    try {
        let action = API_ROUTE+`/get-allow-device/${userId}`
      const response = await requestToken.get(action);
      return response;
    } catch (error) {
     
      console.error('Error in getAllowDevice:', error);
      throw error;
    }
  }
  async getAllowDeviceList(userId) {
    try {
        let action = API_ROUTE+`/get-allow-device-list/${userId}`
      const response = await requestToken.get(action);
      return response;
    } catch (error) {
     
      console.error('Error in getAllowDeviceList:', error);
      throw error;
    }
  }
  async deleteDeviceSubcriptionID(subscriptionID) {
    try {
        let action = API_ROUTE+`/delete-device-subscription/${subscriptionID}`
      const response = await requestToken.get(action);
      return response;
    } catch (error) {
     
      console.error('Error in deleteDeviceSubcriptionID:', error);
      throw error;
    }
  }
  async removeDevice(deviceID) {
    try {
        let action = API_ROUTE+`/remove-device/${deviceID}`
      const response = await requestToken.get(action);
      return response;
    } catch (error) {
     
      console.error('Error in removeDevice:', error);
      throw error;
    }
  }
  async allowUserDevice(payloads) {
    try {
        let action = API_ROUTE+`/set-allow-device`
      const response = await requestToken.post(action, payloads);
      return response;
    } catch (error) {
     
      console.error('Error in allowUserDevice:', error);
      throw error;
    }
  }
  async editDeviceSubscriptionPeriods(payloads) {
    try {
        let action = API_ROUTE+`/edit-device-subscription-periods`
      const response = await requestToken.post(action, payloads);
      return response;
    } catch (error) {
     
      console.error('Error in editDeviceSubscriptionPeriods:', error);
      throw error;
    }
  }

  async getAllowSubscription() {
    try {
      const action = `${API_ROUTE}/get-allow-subscription`;
      const response = await requestToken.get(action);
      return response;
    } catch (error) {
      console.error('Error in getAllowSubscription:', error);
      throw error;
    }
  }

  async checkDeviceIsConnected() {
    try {
      const token = window.localStorage.getItem("isToken");
      if(token){       
        const action = `${API_ROUTE}/check-device-isconnected`;
        const response = await requestToken.get(action);
        return response;
      }else{       
        return { isSuccess: true, result: [] }
      }
    } catch (error) {
      console.error('Error in checkDeviceIsConnected:', error);
      throw error;
    }
  }  

  async sentPendingScheduleMessage(sessionId) {
    try {
        let action = API_ROUTE+`/sent-schedule-message`
      const response = await requestToken.post(action, {session_id: sessionId});
      return response;
    } catch (error) {
     
      console.error('Error in sentPendingScheduleMessage:', error);
      throw error;
    }
  }
  async updateAutoReplyStatus(payloads) {
    try {
        let action = API_ROUTE+`/update-auto-reply-status`
      const response = await requestToken.post(action, payloads);
      return response;
    } catch (error) {
     
      console.error('Error in sentPendingScheduleMessage:', error);
      throw error;
    }
  }
}


export default DeviceService;
