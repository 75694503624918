import { configureStore } from "@reduxjs/toolkit";
import notificationSlice from "./slices/notificationSlice";
import getSessionIdSlice from "./slices/getSessionIdSlice";
import profileSlice from "./slices/userProfileDataSlice";
import getSessionStatusSlice from "./slices/getSessionStatusSlice";
import startSessionSlice from "./slices/startSessionSlice";
import getSessionQRSlice from "./slices/getSessionQRSlice";
import setDeviceSessionId from "./slices/setDeviceSessionId";
import addRoleSlice from "./slices/addRoleSlice";
import getFeatureSlice from "./slices/getFeatureSlice";
import editRoleDataSlice from "./slices/editRoleDataSlice";
import checkDeviceIsConnectionSlice from "./slices/checkDeviceIsConnectionSlice"
export const store = configureStore({
  reducer: {
    notificationState: notificationSlice,
    checkDeviceIsConnectionState: checkDeviceIsConnectionSlice,
    getSessionIdState: getSessionIdSlice,
    profile: profileSlice,
    status: getSessionStatusSlice,
    startsession: startSessionSlice,
    sessionQr: getSessionQRSlice,
    deviceSessionId: setDeviceSessionId,
    addRoleState: addRoleSlice,
    featuresListState: getFeatureSlice,
    editRoleDataState: editRoleDataSlice,
  },
});
