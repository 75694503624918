import { requestToken, requestTokenImage } from '../../api/request_methods';

const API_ROUTE = `${process.env.REACT_APP_NODE_URL}/api`;

class Template {
    async saveTemplateData(data) {
        try {
            // console.log(sessionId, "sessionId!!!!!")
            let action = API_ROUTE + `/template/save-template-data`
            console.log(data, "service calling!!!!!")
            const response = await requestTokenImage.post(action, data);
            return response;
        } catch (error) {
            console.error('Error in save template data:', error);
            throw error;
        }
    }
    
    async updateTemplateData(data) {
        try {
            // console.log(sessionId, "sessionId!!!!!")
            let action = API_ROUTE + `/template/update-template-data`
            console.log(data, "service calling!!!!!")
            const response = await requestTokenImage.post(action, data);
            return response;
        } catch (error) {
            console.error('Error in save template data:', error);
            throw error;
        }
    }
    async savePlaintextTemplateData(data) {
        try {
            // console.log(sessionId, "sessionId!!!!!")
            let action = API_ROUTE + `/template/save-template-plaintext-data`
            console.log(action, "service calling!!!!!")
            const response = await requestToken.post(action, data);
            return response;
        } catch (error) {
            console.error('Error in save template data:', error);
            throw error;
        }
    }

    async saveTextWithMediaTemplateData(data) {
        try {
            // console.log(sessionId, "sessionId!!!!!")
            let action = API_ROUTE + `/template/save-template-textwithmedia-data`
            console.log(data, "service calling!!!!!")
            const response = await requestTokenImage.post(action, data);
            return response;
        } catch (error) {
            console.error('Error in save template data:', error);
            throw error;
        }
    }

    async saveMessageWithButtonTemplateData(data) {
        try {
            // console.log(sessionId, "sessionId!!!!!")
            let action = API_ROUTE + `/template/save-template-textwithbutton-data`
            console.log(data, "service calling!!!!!")
            const response = await requestToken.post(action, data);
            return response;
        } catch (error) {
            console.error('Error in save template data:', error);
            throw error;
        }
    }

    async saveListMessageTemplateData(data) {
        try {
            // console.log(sessionId, "sessionId!!!!!")
            let action = API_ROUTE + `/template/save-template-list-data`
            console.log(data, "service calling!!!!!")
            const response = await requestToken.post(action, data);
            return response;
        } catch (error) {
            console.error('Error in save template data:', error);
            throw error;
        }
    }

    async savelocationData(data) {
        try {
            // console.log(sessionId, "sessionId!!!!!")
            let action = API_ROUTE + `/template/save-template-location-data`
            console.log(data, "service calling!!!!!")
            const response = await requestToken.post(action, data);
            return response;
        } catch (error) {
            console.error('Error in save template data:', error);
            throw error;
        }
    }

    async getTemplateList(user_id) {
        try {
            let action = API_ROUTE + `/template/get-template-list-byuserid/${user_id}`
            console.log(action, "service calling!!!!!")
            const response = await requestToken.get(action);
            console.log(response, "response in service file get-template-list-byuserid!!!!!")
            return response;
        } catch (error) {
            console.error('Error in getContactgroups:', error);
            throw error;
        }
    }


    async deletetemplatebyuserid(id) {
        try {
            let action = API_ROUTE + `/template/delete-template-byuserid/${id}`
            const response = await requestToken.post(action);
            return response;
        } catch (error) {

            console.error('Error in save contact:', error);
            throw error;
        }
    }



    async updatePlaintextTemplateData(data) {
        try {
            // console.log(sessionId, "sessionId!!!!!")
            let action = API_ROUTE + `/template/update-template-plaintext-data`
            console.log(action, "service calling!!!!!")
            const response = await requestToken.post(action, data);
            return response;
        } catch (error) {
            console.error('Error in update template data:', error);
            throw error;
        }
    }

    async updateTextWithMediaTemplateData(data) {
        try {
            // console.log(sessionId, "sessionId!!!!!")
            let action = API_ROUTE + `/template/update-template-textwithmedia-data`
            console.log(data, "service calling!!!!!")
            const response = await requestTokenImage.post(action, data);
            return response;
        } catch (error) {
            console.error('Error in update template data:', error);
            throw error;
        }
    }

    async updateMessageWithButtonTemplateData(data) {
        try {
            // console.log(sessionId, "sessionId!!!!!")
            let action = API_ROUTE + `/template/update-template-textwithbutton-data`
            console.log(data, "service calling!!!!!")
            const response = await requestToken.post(action, data);
            return response;
        } catch (error) {
            console.error('Error in update template data:', error);
            throw error;
        }
    }

    async updateListMessageTemplateData(data) {
        try {
            // console.log(sessionId, "sessionId!!!!!")
            let action = API_ROUTE + `/template/update-template-list-data`
            console.log(data, "service calling!!!!!")
            const response = await requestToken.post(action, data);
            return response;
        } catch (error) {
            console.error('Error in update template data:', error);
            throw error;
        }
    }

    async updatelocationData(data) {
        try {
            // console.log(sessionId, "sessionId!!!!!")
            let action = API_ROUTE + `/template/update-template-location-data`
            console.log(data, "service calling!!!!!")
            const response = await requestToken.post(action, data);
            return response;
        } catch (error) {
            console.error('Error in update template data:', error);
            throw error;
        }
    }


    async sendTemplate(data) {
        try {
            let action = API_ROUTE + `/template/add-template-messages`
            console.log(action, "service calling!!!!!")
            const response = await requestToken.post(action, data);
            return response;
        } catch (error) {
            console.error('Error in sendTemplate:', error);
            throw error;
        }
    }


}

export default Template;
