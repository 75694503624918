import React, { Fragment, useEffect, useState, useRef } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col,
    Alert,
    Label, Table as ReactTable, Badge
} from "reactstrap";
import Select from "react-select";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from 'react-redux';
import BlackList from "../../services/blacklist/Blacklist";
const ImportContacts = (props) => {
    const [centeredModal, setCenteredModal] = useState(false);
    const [csvFile, setCsvFile] = useState("No File Selected");
    const [base64String, setBase64String] = React.useState("");
    const [selectedGroup, setSelectedGroup] = useState();
    const [Errormsg, setErrormsg] = useState('');
    const currentUserData = useSelector(state => state.profile.profile);
    const closeModal = () => {
        setCenteredModal(false)
    }
    const searchSchema = Yup.object().shape({
        group: Yup.mixed().required("Cannot be empty"),
    });
    const fileInputRef = useRef(null);
    function onChangeXLSXFile(e) {
        const file = e.target.files[0];
        const fileName = file.name;
        const fileType = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);

        if (fileType !== "csv") {
            setErrormsg("Please select a CSV file.");
            e.target.value = null; // Clear the file input
        } else {
            setErrormsg(""); // Reset error message
            // Proceed with file handling logic
        }
        if (file) {
            console.log(file, "csv file");
            setCsvFile(file);
            const reader = new FileReader();
            reader.onload = () => {
                const base64 = reader.result;
                setBase64String(base64);
            };
            reader.readAsDataURL(file);
        }
    }
    const handleXlSXUpload = async () => {
        console.log(selectedGroup, "selectedGroup!!!!!!");
        if (base64String == '') {
            setErrormsg('Cannot be empty')
        }
        else {


            const splitImgString = base64String.split(",");
            // const url = `${process.env.REACT_APP_NODE_URL}/api/upload-xls`;
            // const config = {
            //     headers: {
            //         token: localStorage.getItem("rbacToken"),
            //     },
            // };
            const formData = new FormData();
            formData.append("enquiryxlxs", csvFile);
            formData.append("base64String", splitImgString[1]);
            formData.append("user_id", currentUserData?.currentUserData?.result?.user_id);

            const importcontacts = new BlackList();
            importcontacts.importcontactblackbyuserid(formData)
                .then((response) => {
                    // console.log(response,"response in add participant in group!!!!")
                    if (response.isSuccess === true) {
                        toast.success(response?.message);
                        closeModal();
                        props.getAllContacts();
                    } else {
                        toast.error(response?.message);
                    }

                })
                .catch((error) => {
                    console.error("Error fetching user profile:", error);
                });
        }
    };
    //  console.log(props.GroupListData, "GroupListData!!!!!")


    const HandleDownloadSample = () => {
        // Sample CSV data
        const csvData = `contact_name,whatsappno\n abc,91123456789,\n xyz,p1987654321`;

        // Create a Blob object
        const blob = new Blob([csvData], { type: 'text/csv' });

        // Create a URL for the Blob object
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element
        const a = document.createElement('a');

        // Set the download attribute and the href attribute
        a.setAttribute('download', 'sample.csv');
        a.setAttribute('href', url);

        // Simulate a click on the anchor element to trigger the download
        document.body.appendChild(a); // Append the anchor to the document body
        a.click();

        // Clean up: Remove the anchor element and revoke the URL
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    };

    // Usage:
    // <span className="text-secondary" onClick={HandleDownloadSample}>&#40;Download Sample&#41;</span>



    return (
        <div className="vertically-centered-modal">
            <button className='ms-3 btn btn-sm btn-neutral rounded-pill' onClick={() => { setCenteredModal(true) }}>Import Contacts</button>

            <Modal
                isOpen={centeredModal}
                toggle={closeModal}
                className="modal-dialog-centered modal-lg"
                backdrop={true}
            >
                <ModalHeader toggle={closeModal}>
                    <h3>Import Contacts</h3>
                </ModalHeader>

                <ModalBody>
                    <div className="form-group">
                        <label>Select CSV <span className="text-secondary" style={{ cursor: 'pointer' }} onClick={HandleDownloadSample}>&#40;Download Sample&#41;</span></label>
                        <input
                            ref={fileInputRef}
                            onChange={onChangeXLSXFile}
                            autoComplete="off"
                            type="file"
                            name="enquiryCSV"
                            required
                            accept=".csv"
                            className="form-control"
                            id="formFile"
                        />
                        <span className="text-danger">{Errormsg}</span>
                    </div>
                    <hr />
                    <Row className="align-items-center ">
                        <Col md="2" sm="12">
                            <Button color="primary" onClick={handleXlSXUpload} >
                                Import
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>

            </Modal>
        </div>
    );
};

export default ImportContacts;
