import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-hot-toast";

const responseBody = (response) => {
  return response.data;
};

export const request = {
  get: (url, params) =>
    axios
      .get(url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        params,
      })
      .then(responseBody),
  post: (url, body) =>
    axios
      .post(url, body, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(responseBody),
  put: (url, body) =>
    axios
      .put(url, body, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(responseBody),
  del: (url) =>
    axios
      .delete(url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(responseBody),
};
export const requestToken = {
  get: (url, params) =>
    axios
      .get(url, {
        headers: {
          token: `${window.localStorage.getItem("isToken")}`,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        params,
      })
      .then(responseBody),
  post: (url, body) =>
    axios
      .post(url, body, {
        headers: {
          token: `${window.localStorage.getItem("isToken")}`,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(responseBody),
  put: (url, body) =>
    axios
      .put(url, body, {
        headers: {
          token: `${window.localStorage.getItem("isToken")}`,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(responseBody),
  del: (url) =>
    axios
      .delete(url, {
        headers: {
          token: `${window.localStorage.getItem("isToken")}`,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then(responseBody),
};

export const requestTokenImage = {
    post: (url, body) =>
      axios
        .post(url, body, {
          headers: {
            token: `${window.localStorage.getItem("isToken")}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(responseBody),
  };

const request_methods = {
  request,
  requestToken,
  requestTokenImage,
};

export default request_methods;
