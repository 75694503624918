import React from 'react';
import { Fragment, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from 'react-redux';
import TemplateService from "../../../services/Template/Template";
import toast from "react-hot-toast";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Alert,
  Label, Table as ReactTable, Badge
} from "reactstrap";
const TextWithMedia = (props) => {
  const currentUserData = useSelector(state => state.profile.profile);
  const searchSchema = Yup.object().shape({
    template_name: Yup.string().required("Cannot be empty"),
    template_file: Yup.string().required("Cannot be empty"),
    template_media_caption: Yup.string().required("Cannot be empty"),
  });
  const [initialvalues, setInitialvalues] = useState({
    idtemplate_data: '',
    template_name: '',
    template_file: '',
    template_media_caption: '',
  });
  const [showModal, setShowModal] = useState(false)
  const showShortCode = () => {
    setShowModal(true)
  }
  const closeModal = () => {
    setShowModal(false)
  }
  useEffect(() => {
    if (props.isedit) {
      if (props.editTemplateData) {
        console.log(props.editTemplateData, "editTemplateData!!!!!!!!")
        setInitialvalues(() => ({
          idtemplate_data: props.editTemplateData.idtemplate_data,
          template_name: props.editTemplateData.template_name,
          template_file: '',
          template_media_caption: props.editTemplateData.template_media_caption,
        }))
      }
    }
  }, [props.isedit])
  return (
    <div className="tab-pane fade show active" id="mode_1" role="tabpanel" aria-labelledby="home-tab4">
      <Formik
        validationSchema={searchSchema}
        initialValues={initialvalues}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          console.log(values, "values in text with media template!!!!")
          const data = {
            user_id: currentUserData?.currentUserData?.result?.user_id,
            idtemplate_data: values.idtemplate_data,
            template_name: values.template_name,
            template_file: values.template_file,
            template_media_caption: values.template_media_caption,
          }

          const saveTemplate = new TemplateService();
          if (props.isedit) {
            console.log(props.isedit, "is edit ")
            saveTemplate
              .updateTextWithMediaTemplateData(data)
              .then((response) => {
                console.log(response, "response in add participant in group!!!!")
                if (response.isSuccess === true) {
                  toast.success(response?.message);
                } else {
                  toast.error(response?.message);
                }
                resetForm();
                setSubmitting(false);
                props.setEdit(false);
                props.getTemplates();
              })
              .catch((error) => {
                console.error("Error fetching user profile:", error);
              });
          } else {
            saveTemplate
              .saveTextWithMediaTemplateData(data)
              .then((response) => {
                console.log(response, "response in add participant in group!!!!")
                if (response.isSuccess === true) {
                  toast.success(response?.message);
                } else {
                  toast.error(response?.message);
                }
                resetForm();
                setSubmitting(false)
              })
              .catch((error) => {
                console.error("Error fetching user profile:", error);
              });
          }

        }}
      >
        {({ values, setFieldValue, isSubmitting, errors }) =>
          <Form>
            <Fragment>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Template Name</label>
                    <input type="text" name="template_name" placeholder="Enter Your Template Name" className="form-control" value={values.template_name} onChange={(e) => setFieldValue('template_name', e.target.value)} />
                    <ErrorMessage name="template_name">
                      {(msg) => (
                        <div className="error" style={{ color: "red" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Select File</label>
                    <input id="phone" type="file" className="form-control" name="template_file" onChange={(e) => setFieldValue('template_file', e.currentTarget.files.length > 0
                      ? e.currentTarget.files[0]
                      : null)} />
                    {/* <small> Supported file type:</small> <small className="text-danger">jpg,jpeg,png,webp,pdf,docx,xlsx,csv,txt</small> */}
                    <ErrorMessage name="template_file">
                      {(msg) => (
                        <div className="error" style={{ color: "red" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-row mb-1">
                    <label className="col-6">Media Caption:</label>
                    <div className="col-6">
                      <button type="button" data-toggle="modal" data-target="#help-modal" className="btn btn-neutral btn-sm float-right" onClick={showShortCode}><i className="fas fas fa-code"></i>&nbsp;Shortcodes</button>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea className="form-control h-200" name="template_media_caption" maxLength="1000" value={values.template_media_caption} onChange={(e) => setFieldValue('template_media_caption', e.target.value)}></textarea>
                    <ErrorMessage name="template_media_caption">
                      {(msg) => (
                        <div className="error" style={{ color: "red" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="col-sm-12">
                  <button type="submit" className="btn btn-outline-primary submit-button">{props.isedit ? 'Update Template' : 'Save Template'}</button>
                </div>
              </div>
            </Fragment>
          </Form>
        }
      </Formik>
      <Modal
        isOpen={showModal}
        toggle={closeModal}
        className="modal-dialog-centered modal-lg"
        backdrop={true}
      >
        <ModalBody>

          <Row className="align-items-center ">
            <Col>
              <div className="d-flex d-flex justify-content-between"><h4>Shortcode</h4><span className='text-end' onClick={closeModal} style={{ cursor: 'pointer' }}>X</span></div>
              <Row>
                <p>&#123;name&#125; &#61; recipient can see his/her name</p>
                <p>&#123;phone_number&#125; &#61; recipient can see his/her phone_number</p>
                <p>&#123;my_name&#125; &#61; recipient can see your name</p>
                <p>&#123;my_email&#125; &#61; recipient can see your email</p>
                <p>&#123;my_contact_number&#125; &#61; recipient can see your contact number</p>
              </Row>

            </Col>
          </Row>



          <hr />
          <Row className="align-items-center ">
            <Col md="2" sm="12">
              <Button color="primary" onClick={closeModal}>
                Close
              </Button>
            </Col>
          </Row>
        </ModalBody>

      </Modal>
    </div>

  );
}

export default TextWithMedia;
