import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
  profile: {
    isSuccess: false,
    isFetching: false,
    isError: false,
    currentUserData: "",
  },
};

export const getProfileData = createAsyncThunk(
  "profile/getProfileData",
  async () => {
    const config = {
      headers: {
        token: localStorage.getItem("isToken"),
      },
    };
    const url = `${process.env.REACT_APP_NODE_URL}/api/login/get-profile-data`;
    const response = await axios.get(url, config);
    return response.data;
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearProfileDataSlice: (state) => {
      state.profile.isError = false;
      state.profile.isFetching = false;
      state.profile.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfileData.pending, (state) => {
      state.profile.isFetching = true;
    });
    builder.addCase(getProfileData.fulfilled, (state, action) => {
      state.profile.isFetching = false;
      state.profile.isError = false;
      state.profile.isSuccess = true;
      state.profile.currentUserData = action.payload;
    });
    builder.addCase(getProfileData.rejected, (state) => {
      state.profile.isFetching = false;
      state.profile.isError = true;
    });
  },
});

export const { clearProfileDataSlice } = profileSlice.actions;
export default profileSlice.reducer;
