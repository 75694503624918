import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  sessionStatus: {
    isSuccess: false,
    isFetching: false,
    isError: false,
    status: "",
  },
};

export const getSessionStatus = createAsyncThunk(
  "sessionStatus/sessionStatusSlice",
  async (sessionId) => {
    console.log(sessionId, 'sessionidf')
    const config = {
      headers: {
        token: localStorage.getItem("isToken"),
      },
    };
    const url = `${process.env.REACT_APP_NODE_URL}/api/devices/status-session/${sessionId}`;
    const response = await axios.get(url, config);
    return response.data;
  }
);

const sessionStatusSlice = createSlice({
  name: "sessionStatus",
  initialState,
  reducers: {
    clearSessionSlice: (state) => {
      state.sessionStatus.isError = false;
      state.sessionStatus.isFetching = false;
      state.sessionStatus.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSessionStatus.pending, (state) => {
      state.sessionStatus.isFetching = true;
    });
    builder.addCase(getSessionStatus.fulfilled, (state, action) => {
      state.sessionStatus.isFetching = false;
      state.sessionStatus.isError = false;
      state.sessionStatus.isSuccess = true;
      state.sessionStatus.status = action.payload;
    });
    builder.addCase(getSessionStatus.rejected, (state) => {
      state.sessionStatus.isFetching = false;
      state.sessionStatus.isError = true;
    });
  },
});

export const { clearSessionSlice } = sessionStatusSlice.actions;
export default sessionStatusSlice.reducer;
