import { requestToken } from '../../api/request_methods';

const API_ROUTE = `${process.env.REACT_APP_NODE_URL}/api`;

class UserService {
  async getUserListData() {
    try {
      let action = API_ROUTE + `/login/get-user-data`
      const response = await requestToken.get(action);
      return response;
    } catch (error) {

      console.error('Error in getUserProfile:', error);
      throw error;
    }
  }
  async getUserRoleData(userId) {
    try {
      let action = API_ROUTE + `/login/get-user-role/${userId}`
      const response = await requestToken.get(action);
      return response;
    } catch (error) {

      console.error('Error in getUserRoles:', error);
      throw error;
    }
  }
  async removeUserRole(payload) {
    try {
      let action = API_ROUTE + `/login/delete-role`
      const response = await requestToken.post(action, payload);
      return response;
    } catch (error) {

      console.error('Error in deleteRoles:', error);
      throw error;
    }
  }
  async assignUserRole(payload) {
    try {
      let action = API_ROUTE + `/login/assign-role`
      const response = await requestToken.post(action, payload);
      return response;
    } catch (error) {

      console.error('Error in assignRoles:', error);
      throw error;
    }
  }
  async deleteUser(userId) {
    try {
      const action = API_ROUTE + '/login/delete-user';
      const response = await requestToken.post(action, { userId });
      return response
    } catch (error) {
      console.error('Error in deleteUser:', error);
      throw error;
    }
  }
  

}


export default UserService;
